import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

import { withStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import ShowMoreIcon from '../../assets/icons/showmore.svg';
import ButtonsDialog from './CustomButtonsDialog';
import useMobile from '../../hooks/useMobile';

const styles = {
  table: {},
  cell: {
    padding: '10px',
  },
  newStatus: {
    backgroundColor: '#75B0FA',
    border: '1px solid #4A93F0',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  activeStatus: {
    backgroundColor: '#4CAF50',
    border: '1px solid #388E3C',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  cancelledStatus: {
    backgroundColor: '#FA5A16',
    border: '1px solid #E54C0B',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  appliedStatus: {
    backgroundColor: '#FFC400',
    border: '1px solid #FFC400',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  updatedStatus: {
    backgroundColor: '#8F00FF',
    border: '1px solid #8F00FF',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  approvedStatus: {
    backgroundColor: '#4CAF50',
    border: '1px solid #388E3C',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  selectedRow: {
    backgroundColor: '#D7E8FF',
  },
};

const CustomDataGrid = ({
  classes,
  rows,
  columns,
  paging,
  selectedItem,
  setSelectedItem,
  fetchData,
  actions,
  setActions,
  buttons,
  padding,
}) => {
  // const { total, per_page, current_page } = paging;
  const [page, setPage] = useState(paging?.current_page - 1);
  const [rowsPerPage, setRowsPerPage] = useState(paging?.per_page);

  const [gridWidth, setGridWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      setGridWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, [window.innerWidth]);

  useEffect(() => {
    setPage(paging?.current_page - 1);
    setRowsPerPage(paging?.per_page);
  }, [paging]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelectedItem(null);
    fetchData(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setSelectedItem(null);
    fetchData(1, newRowsPerPage);
  };

  const handleRowClick = (item) => {
    if (selectedItem && selectedItem.id === item.id) {
      setSelectedItem(null);
      return;
    } else if (selectedItem && selectedItem.id !== item.id) {
      setSelectedItem(item);
      return;
    } else {
      setSelectedItem(item);
      return;
    }
  };
  const isMobile = useMobile(767);

  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        padding: padding ? padding : '20px',
      }}
    >
      {gridWidth > 800 ? (
        <div
          style={{
            border: '1px solid #D9D9D9',
            borderRadius: '10px',
            width: '100%',
            backgroundColor: '#fff',
            overflowX: 'auto',
          }}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {columns.map((item, index) => (
                  <TableCell
                    key={item?.field}
                    style={{ width: item.width }}
                    className={classes.cell}
                  >
                    {item.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  className={
                    selectedItem && selectedItem.id === row.id
                      ? classes.selectedRow
                      : ''
                  }
                  onClick={() => handleRowClick(row)}
                >
                  {columns.map((column, columnIndex) => (
                    <TableCell key={columnIndex} className={classes.cell}>
                      {row[column.field] ? row[column.field] : column.field}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {paging && (
            <>
              {paging?.total === 0 && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '40vh',
                    color: '#D9D9D9',
                  }}
                >
                  <span></span>
                </div>
              )}
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={paging?.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </div>
      ) : (
        <div>
          <Table className={classes.table}>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <div
                  key={row?.id}
                  style={{
                    margin: isMobile ? '0 0 15px 0' : '0 10px 15px',
                    backgroundColor: '#fff',
                    padding: '15px',
                    borderRadius: '6px',
                    boxShadow: '0px 2px 8px 0px #00000026',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderBottom: '1px solid rgba(224, 224, 224, 1)',
                      paddingBottom: '10px',
                    }}
                  >
                    <div
                      className="d-flex gap-3"
                      style={{
                        width: '100%',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleRowClick(row)}
                    >
                      <div className="d-flex flex-wrap justify-content-between flex-grow-1 row-gap-2 column-gap-3">
                        {columns.slice(0, 3).map((column, index) => (
                          <div
                            key={index}
                            style={{
                              marginBottom: '8px',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '12px',
                                color: '#666',
                                marginBottom: '2px',
                              }}
                            >
                              {column.headerName}
                            </span>
                            <span
                              style={{
                                fontSize: '14px',
                                color: '#262626',
                              }}
                            >
                              {row[column.field] || '-'}
                            </span>
                          </div>
                        ))}
                      </div>
                      <div className="d-flex flex-column row-gap-2 justify-content align-items-center">
                        <ExpandMoreIcon
                          style={{
                            transform:
                              selectedItem && selectedItem.id === row.id
                                ? 'rotate(180deg)'
                                : 'rotate(0deg)',
                            transition: 'transform 0.3s ease',
                          }}
                        />
                        {setActions && (
                          <div
                            onClick={() => {
                              setSelectedItem(row);
                              setActions(true);
                            }}
                            style={{
                              cursor: 'pointer',
                              // border: '1px solid #E8E8E8',
                              borderRadius: '50%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '8px',
                            }}
                          >
                            <img
                              src={ShowMoreIcon}
                              alt="More options"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      maxHeight: selectedItem && selectedItem.id === row.id ? '500px' : '0',
                      overflow: 'hidden',
                      transition: 'all 0.3s ease-in-out',
                    }}
                  >
                    {columns.length > 3 && (
                      <div
                        className="d-flex flex-wrap gap-3 justify-content-between"
                        style={{ marginTop: '15px' }}
                      >
                        {columns.slice(3).map((column, index) => (
                          <div
                            key={index}
                            style={{
                              marginBottom: '12px',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '12px',
                                color: '#666',
                                display: 'block',
                                marginBottom: '4px',
                              }}
                            >
                              {column.headerName}
                            </span>
                            <span
                              style={{
                                fontSize: '14px',
                                color: '#262626',
                              }}
                            >
                              {row[column.field] || '-'}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </TableBody>
          </Table>
          {paging && (
            <>
              {paging?.total === 0 && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '40vh',
                    color: '#D9D9D9',
                  }}
                >
                  <span>No data available</span>
                </div>
              )}
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={paging?.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </div>
      )}
      <ButtonsDialog
        actions={actions}
        setActions={setActions}
        selectedItem={selectedItem}
        buttons={buttons}
      />
    </div>
  );
};

export default withStyles(styles)(CustomDataGrid);
