import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ShowMoreIcon from '../../../../assets/icons/showmore.svg';
import ApplicantPopup from '../../../../components/General/ApplicantPopup';
import ErrorModal from '../../../../components/General/ErrorModal';
import ProposalHistoryModal from '../../../../components/General/ProposalHistoryModal';
import { API_BASE_URL } from '../../../../config/constants';
import useMobile from '../../../../hooks/useMobile';
import { capitalizeFirstLetter } from '../../../../utils/helper';
import '../TemporaryJobs/PostingApplicants.css';
import ButtonsDialog from '../../../../components/General/CustomButtonsDialog';

const styles = {
  table: {},
  cell: {
    padding: '10px',
  },
  newStatus: {
    backgroundColor: '#75B0FA',
    border: '1px solid #4A93F0',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  activeStatus: {
    backgroundColor: '#4CAF50',
    border: '1px solid #388E3C',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  cancelledStatus: {
    backgroundColor: '#ff9900',
    border: '1px solid #ff9900',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  completedStatus: {
    backgroundColor: '#4CAF50',
    border: '1px solid #388E3C',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  updatedStatus: {
    backgroundColor: '#B6A8FF',
    border: '1px solid #7C67EB',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  appliedStatus: {
    backgroundColor: '#FFC400',
    border: '1px solid #FFC400',
    borderRadius: '16px',
    padding: '1px 8px',
  },
  selectedRow: {
    backgroundColor: '#D7E8FF',
  },

  proposalSentStatus: {
    backgroundColor: '#6f8edc',
    border: '1px solid #6f8edc',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  proposalAcceptStatus: {
    backgroundColor: '#f213a5',
    border: '1px solid #f213a5',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
  proposalDeclineStatus: {
    backgroundColor: '#393d2e',
    border: '1px solid #393d2e',
    borderRadius: '16px',
    padding: '1px 8px',
    color: 'white',
  },
};

const getStatusStyle = (status, classes) => {
  switch (status) {
    case 'new':
      return classes.proposalSentStatus;
    case 'active':
      return classes.proposalSentStatus;
    case 'cancelled':
      return classes.proposalDeclineStatus;
    case 'completed':
      return classes.completedStatus;
    case 'updated':
      return classes.updatedStatus;
    case 'applied':
      return classes.appliedStatus;
    case 'accepted':
      return classes.proposalAcceptStatus;
    case 'hired':
      return classes.activeStatus;
    default:
      return status;
  }
};

const ProposalsDataGrid = ({
  classes,
  rows,
  columns,
  paging,
  selectedItem,
  setSelectedItem,
  fetchApplicants,
  postingId,
  setInterviewDetailsOpen,
  actionButtons,
  actions,
  setActions,
}) => {
  const { total, per_page, current_page } = paging;
  const [page, setPage] = useState(current_page - 1);
  const [rowsPerPage, setRowsPerPage] = useState(per_page);

  const [errorApproveDialog, setErrorApproveDialog] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);
  const [historyModal, setHistoryModal] = useState(false);
  const navigate = useNavigate();

  const getRate = (user_location) => {
    var rate = user_location.filter((location) => {
      return location.is_current == true;
    });

    if (rate.length != 0) {
      return rate[0].desired_rate;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    setPage(current_page - 1);
    setRowsPerPage(per_page);
  }, [paging, current_page, per_page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelectedItem(null);
    fetchApplicants(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setSelectedItem(null);
    fetchApplicants(1, newRowsPerPage);
  };

  const handleRowClick = (item) => {
    if (selectedItem && selectedItem.id === item.id) {
      setHistoryModal(false);
      setSelectedItem(null);

      return;
    } else if (selectedItem && selectedItem.id !== item.id) {
      setSelectedItem(item);
      return;
    } else {
      setSelectedItem(item);
      return;
    }
  };

  const [isApplicantDataOpen, setIsApplicantDataOpen] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const handleNameClick = (item) => {
    setSelectedApplicant(item);
    setIsApplicantDataOpen(true);
  };
  const handleApplicantDataClose = () => {
    setSelectedApplicant(null);
    setIsApplicantDataOpen(false);
  };

  const hireApplicant = (id) => {
    if (id) {
      axios
        .put(`${API_BASE_URL}/owner/applicants/${id}/hire`, null, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        })
        .then((res) => {
          fetchApplicants(page + 1, rowsPerPage);
        })
        .catch((res) => {
          setErrorApproveDialog(true);
          setErrorMessages(res.response.data.message.description);
        });
    }
  };

  const tableData = rows
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((item, index) => {
      return {
        id: item.id,
        date: item.date_time,
        hiring_rate: <> $ {item.hiring_rate}</>,
        status: (
          <>
            <span
              className={`${getStatusStyle(
                item.proposal_status.toLowerCase(),
                classes
              )}`}
            >
              {capitalizeFirstLetter(item.proposal_status)}
            </span>
          </>
        ),
        days: (
          <>
            {item.proposal_posting_schedules.map((day) => {
              return (
                <Chip
                  label={capitalizeFirstLetter(day.schedule_day)}
                  color="primary"
                  className="m-1"
                />
              );
            })}
          </>
        ),
        applicants_count: (
          <>
            <Button
              variant="outline-primary"
              className="d-flex justify-content-center align-items-center w-100 fw-bold"
              style={{
                border: '1px solid #2561B0',
                borderRadius: '6px',
                color: '#2561B0',
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                setSelectedItem(item);
                setHistoryModal(true);
              }}
              // disabled={selectedItem != null ? false : true}
            >
              History
            </Button>
          </>
        ),
      };
    });

  const isMobile = useMobile(767);

  return (
    <div>
      {isMobile && (
        <Table className={classes.table}>
          <TableBody>
            {tableData.map((row, rowIndex) => (
              <div
                key={row?.id}
                style={{
                  margin: isMobile ? '0 0 15px 0' : '0 10px 15px',
                  backgroundColor: '#fff',
                  padding: '15px',
                  borderRadius: '6px',
                  boxShadow: '0px 2px 8px 0px #00000026',
                }}
              >
                <div
                  className="d-flex flex-row justify-content-between align-items-center"
                  style={{
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    paddingBottom: '10px',
                  }}
                >
                  <div
                    className="d-flex gap-3"
                    style={{
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  >
                    <div className="d-flex flex-wrap justify-content-between flex-grow-1 row-gap-2 column-gap-3">
                      {columns.slice(0, 3).map((column, index) => (
                        <div
                          className="d-flex flex-column"
                          key={index}
                          style={{
                            marginBottom: '8px',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '12px',
                              color: '#666',
                              marginBottom: '2px',
                            }}
                          >
                            {column.headerName}
                          </span>
                          <span
                            style={{
                              fontSize: '14px',
                              color: '#262626',
                            }}
                          >
                            {row[column.field] || '-'}
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className="d-flex flex-column row-gap-2 justify-content align-items-center">
                      <ExpandMoreIcon
                        style={{
                          transform:
                            selectedItem && selectedItem.id === row.id
                              ? 'rotate(180deg)'
                              : 'rotate(0deg)',
                          transition: 'transform 0.3s ease',
                        }}
                        onClick={() => handleRowClick(row)}
                      />
                      {setActions && (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          onClick={() => {
                            setSelectedItem(row);
                            setActions(true);
                          }}
                          style={{
                            cursor: 'pointer',
                            // border: '1px solid #E8E8E8',
                            borderRadius: '50%',
                            padding: '8px',
                          }}
                        >
                          <img src={ShowMoreIcon} alt="More options" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    maxHeight:
                      selectedItem && selectedItem.id === row.id
                        ? '500px'
                        : '0',
                    overflow: 'hidden',
                    transition: 'all 0.3s ease-in-out',
                  }}
                >
                  {columns.length > 3 && (
                    <div
                      className="d-flex flex-wrap gap-3 justify-content-between"
                      style={{ marginTop: '15px' }}
                    >
                      {columns.slice(3).map((column, index) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: '12px',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '12px',
                              color: '#666',
                              display: 'block',
                              marginBottom: '4px',
                            }}
                          >
                            {column.headerName}
                          </span>
                          <span
                            style={{
                              fontSize: '14px',
                              color: '#262626',
                            }}
                          >
                            {row[column.field] || '-'}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </TableBody>
        </Table>
      )}

      {!isMobile && (
        <div
          style={{
            border: '1px solid #D9D9D9',
            borderRadius: '10px',
            width: '100%',
          }}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {columns.map((item, index) => (
                  <TableCell
                    key={index}
                    style={{ width: item.width }}
                    className={classes.cell}
                  >
                    {item.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(item)}
                    className={
                      selectedItem && selectedItem.id === item.id
                        ? classes.selectedRow
                        : ''
                    }
                  >
                    <TableCell className={classes.cell}>
                      {item.date_time}
                    </TableCell>

                    <TableCell className={classes.cell}>
                      $ {item.hiring_rate}
                    </TableCell>

                    {/* <TableCell className={classes.cell}>
                  {item.rate}
                </TableCell> */}

                    <TableCell className={classes.cell}>
                      {/* <Chip label={capitalizeFirstLetter(item.proposal_status)} color="secondary" /> */}

                      <span
                        className={`${getStatusStyle(
                          item.proposal_status.toLowerCase(),
                          classes
                        )}`}
                      >
                        {capitalizeFirstLetter(item.proposal_status)}
                      </span>
                    </TableCell>

                    <TableCell className={classes.cell}>
                      {item.proposal_posting_schedules.map((day) => {
                        return (
                          <Chip
                            label={capitalizeFirstLetter(day.schedule_day)}
                            color="primary"
                            className="m-1"
                          />
                        );
                      })}
                    </TableCell>

                    <TableCell className={classes.cell}>
                      <Button
                        variant="outline-primary"
                        className="d-flex justify-content-center align-items-center w-100 fw-bold"
                        style={{
                          border: '1px solid #2561B0',
                          borderRadius: '6px',
                          color: '#2561B0',
                          whiteSpace: 'nowrap',
                        }}
                        onClick={() => {
                          setSelectedItem(item);
                          setHistoryModal(true);
                        }}
                        // disabled={selectedItem != null ? false : true}
                      >
                        History
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      )}

      {selectedApplicant && (
        <ApplicantPopup
          isOpen={isApplicantDataOpen}
          onClose={handleApplicantDataClose}
          selectedApplicant={selectedApplicant}
        />
      )}

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {errorApproveDialog && (
        <ErrorModal
          open={errorApproveDialog}
          handleClose={() => setErrorApproveDialog(false)}
          errorMessage={errorMessages}
        />
      )}

      {historyModal && (
        <ProposalHistoryModal
          selectedItem={selectedItem}
          open={historyModal}
          handleClose={() => setHistoryModal(false)}
        />
      )}

      <ButtonsDialog
        actions={actions}
        setActions={setActions}
        selectedItem={selectedItem}
        buttons={actionButtons}
        subHeading="Manage Proposal"
      />
    </div>
  );
};
export default withStyles(styles)(ProposalsDataGrid);
