import React from 'react';
import InputMask from 'react-input-mask';
import { TextField } from '@mui/material';

const PhoneNumberInput = ({
  value,
  onChange,
  label = 'Phone Number',
  isRequired = false,
  name = 'phone',
  sx,
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <InputMask
      mask="+1 (999) 999 - 9999"
      maskChar="_"
      value={value}
      onChange={handleChange}
    >
      {(inputProps) => (
        <TextField
          required={isRequired}
          className="w-100"
          label={label}
          name={name}
          variant="outlined"
          inputProps={{ ...inputProps, type: 'tel' }}
          sx={sx}
        />
      )}
    </InputMask>
  );
};

export default PhoneNumberInput;
