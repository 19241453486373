import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/icons/Logo.svg';

import shoulder from '../../assets/images/registerPracticeOwner.jpg';
import CheckoutForm from './CheckoutForm';

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { Box } from '@mui/material';
import { API_BASE_URL, STRIPE_API_KEY } from '../../config/constants';

export default function PaymentMethod() {
  const stripePromise = loadStripe(STRIPE_API_KEY);
  const [selectedValue, setSelectedValue] = useState('1_1');
  const [subs, setSubs] = useState([]);
  const [plan, setSubscription] = useState([]);
  const [subPlan, setSubPlan] = useState(null);
  const [subPlanPrice, setSubPlanPrice] = useState(null);
  const [subPlanDuration, setSubPlanDuration] = useState(null);
  const [subPlanCycle, setSubPlanCycle] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [paymentDialog, setPaymentDialog] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [setupIntent, setSetupIntent] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchPlan = async function (plan_id, subscription_plan_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${API_BASE_URL}/subscriptions?plan_id=${plan_id}&subscription_plan_id=${subscription_plan_id}`
        )
        .then((response) => {
          resolve(response.data.data[0]);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const fetchIntent = async function () {
    const authToken = localStorage.getItem('auth_token');
    return new Promise((resolve, reject) => {
      const setup_intent_response = axios
        .post(
          `${API_BASE_URL}/intent`,
          {},
          { headers: { Authorization: `Bearer ${authToken}` } }
        )
        .then((res) => {
          setPaymentDialog(true);
          setClientSecret(res.data.setup_intent.client_secret);
          // setClientSecret(res.data.clientSecret); // For PaymentIntent PaymentElement
          setSetupIntent(res.data.setup_intent);
        })
        .catch((e) => {
          setError(e.message);
        });
    });
  };

  useEffect(() => {
    // setIsLoading(true);
    const plan_id = localStorage.getItem('plan_id');
    const subscription_plan_id = localStorage.getItem('subscription_plan_id');

    if (!plan_id && !subscription_plan_id) {
      navigate(-1);
    }
    async function fetchData() {
      const response = await fetchPlan(plan_id, subscription_plan_id).then(
        (res) => {
          if (!ignore) {
            setSubs(res);
            setSubPlan((...subPlan) => [...subPlan, res.subscription_plans[0]]);
            setSubPlanDuration(res.subscription_plans[0].cycle_type);
            setSubPlanPrice(res.subscription_plans[0].amount);
            setSubPlanCycle(res.subscription_plans[0].cycle);
            setIsLoading(false);
          }
        }
      );

      const intent_response = await fetchIntent((res) => {
        setPaymentDialog(true);
        setClientSecret(res.data.setup_intent.client_secret);
        setSetupIntent(res.data.setup_intent);
      });
    }

    let ignore = false;
    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px',
      // See all possible variables below
    },
  };

  const handleComplete = (event) => {};

  const CardPlaceHolder = () => {
    return (
      <Container>
        <Placeholder
          as={Card.Title}
          animation="glow"
          className="h-100 mb-3 h-100"
          style={{ height: '50px' }}
        >
          <Placeholder style={{ height: '35px' }} xs={6} />{' '}
          <Placeholder style={{ height: '35px' }} xs={5} />
        </Placeholder>
        <Placeholder as={Card.Title} animation="glow" className="mb-3">
          <Placeholder style={{ height: '35px' }} xs={5} />{' '}
          <Placeholder style={{ height: '35px' }} xs={6} />
        </Placeholder>
        <Placeholder as={Card.Title} animation="glow" className="mb-3">
          <Placeholder style={{ height: '35px' }} xs={11} />
        </Placeholder>
        <Placeholder as={Card.Title} animation="glow" className="mb-3">
          <Placeholder style={{ height: '35px' }} xs={11} />
        </Placeholder>
        <Placeholder as={Card.Title} animation="glow" className="mb-3">
          <Placeholder style={{ height: '35px' }} xs={11} />
        </Placeholder>
        <Placeholder as={Card.Title} animation="glow" className="mb-3">
          {/* <Placeholder style={{ height: '35px' }} xs={5} />
        <Placeholder style={{ height: '35px' }} xs={6} /> */}
          <Placeholder.Button bg="light" xs={5} aria-hidden="true" />{' '}
          <Placeholder.Button xs={6} aria-hidden="true" />
        </Placeholder>
      </Container>
    );
  };

  const PlanPlaceHolder = () => {
    return (
      <Card.Body>
        <Placeholder as={Card.Title} animation="glow" className="mb-5">
          <Placeholder xs={6} style={{ height: '35px' }} />
        </Placeholder>
        <Placeholder as={Card.Text} animation="glow" className="mb-8">
          <Placeholder xs={7} style={{ height: '20px' }} />
          <Placeholder xs={2} style={{ height: '20px' }} />{' '}
          <Placeholder style={{ height: '35px' }} xs={4} />{' '}
        </Placeholder>
        <Placeholder as={Card.Text} animation="glow" className="mb-3 mt-5">
          <Placeholder style={{ height: '25px' }} xs={6} />
          <Placeholder style={{ height: '25px' }} xs={8} />
        </Placeholder>
      </Card.Body>
    );
  };

  return (
    <>
      <Row className="justify-content-between">
        <Col md={9}>
          <Box className="mx-2 mx-md-5 my-2 my-md-4">
            <Image src={logo} />
            {/* <div className='text-grey fs-2 my-4 fw-semibold'>
              Payment Card Detail
            </div> */}
            <div>
              <hr />
              <Row className="justify-content-between mx-0 my-5">
                <Col
                  md={12}
                  lg={4}
                  className={`justify-content-center rounded mb-5 mt-2 `}
                >
                  <Box
                    className="py-4 px-3 h-100"
                    style={{
                      backgroundColor: '#FAFAFA',
                      boxShadow: '0 4px 50px rgba(0,0,0,0.1)',
                    }}
                  >
                    {isLoading ? (
                      <PlanPlaceHolder />
                    ) : (
                      <div style={{ backgroundColor: '#FAFAFA' }}>
                        <h4> {subs.name}</h4>
                        <p
                          style={{ fontSize: '0.9rem', color: '#595959' }}
                          className="w-75"
                        >
                          {subs.features}
                        </p>
                        <br />
                        <p className="mb-0 text-lightgrey">Starts at</p>
                        <span className="fw-semibold display-6">
                          $ {subPlanPrice}
                        </span>
                        <span className="text-lightgrey fs-5 fw-semibold">
                          / {subPlanDuration}
                        </span>
                        <div
                          className="mt-3"
                          style={{
                            border: 'none',
                            borderRadius: '0.5rem',
                            padding: '10px',
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            className={'border border-2 rounded mb-3'}
                          >
                            <Grid item xs={1} className="p-3">
                              <FormControl component="fieldset">
                                <RadioGroup>
                                  <FormControlLabel
                                    control={<Radio color="primary" />}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid
                              className="d-flex ms-3 align-items-center"
                              item
                              xs={6}
                            >
                              <div className="text-start">
                                <label className="text-start">
                                  {subPlanCycle} Month Plan
                                  <br />{' '}
                                  {/* <span className="fw-semibold">0.00</span> */}
                                </label>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                  </Box>
                </Col>

                <Col
                  md={12}
                  lg={8}
                  className={`justify-content-center rounded mb-5 mt-2 `}
                >
                  <Box
                    className="py-4 px-3 h-100"
                    sx={{
                      backgroundColor: '#FAFAFA',
                      boxShadow: '0 4px 50px rgba(0,0,0,0.1)',
                    }}
                  >
                    <Row className="ms-1 mb-4">
                      <Col>
                        <h4> Card Details</h4>
                      </Col>
                    </Row>
                    {!paymentDialog ? (
                      <CardPlaceHolder />
                    ) : (
                      <Elements
                        stripe={stripePromise}
                        options={{
                          clientSecret,
                          onComplete: handleComplete,
                          appearance: appearance,
                        }}
                      >
                        <CheckoutForm
                          price={subPlanPrice}
                          setup_intent={setupIntent}
                        />
                      </Elements>
                    )}
                  </Box>
                </Col>
              </Row>
            </div>
          </Box>
        </Col>
        <Col className="d-none d-md-grid p-0 m-0" md={3}>
          <Image
            style={{ height: '100vh' }}
            className="img-fluid ms-auto"
            src={shoulder}
          />
        </Col>
      </Row>
    </>
  );
}
