import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUser, setRoleType, setUser } from '../../redux/slices/userSlice';

// import CheckIcon from '@mui/icons-material/Check';
// import ClearIcon from '@mui/icons-material/Clear';
// import InputAdornment from '@mui/material/InputAdornment';
import { API_BASE_URL } from '../../config/constants';
// import useAddressLookup from '../../hooks/useAddressLookup';
import GeneralAddress from '../../pages/GeneralAddress';

//Step 1
export default function PersonalDetails({ setActiveStep }) {
  const user = useSelector(selectUser);
  const [title, setTitle] = useState(user && user.title ? user.title : '');
  // const [zip, setZip] = useState(user && user.zip ? user.zip : '');
  // const [state, setState] = useState(
  //   user && user.state_id ? user.state_id : ''
  // );
  const [firstName, setFirstName] = useState(
    user && user.first_name ? user.first_name : ''
  );
  // const [street, setStreet] = useState(user && user.street ? user.street : '');
  const [lastName, setLastName] = useState(
    user && user.last_name ? user.last_name : ''
  );
  // const [city, setCity] = useState(user && user.city_id ? user.city_id : '');

  const [userAddressData, setUserAddressData] = useState({
    zip: user && user.zip ? user.zip : '',
    state_id: user && user.state_id ? user.state_id : '',
    city_id: user && user.city_id ? user.city_id : '',
    address: user && user.street ? user.street : '',
  });

  const updateUserAddress = (field, value) => {
    setUserAddressData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // const [stateOptions, setStateOptions] = useState([]);
  // const [cityOptions, setCityOptions] = useState([]);
  // const [cityQuery, setCityQuery] = useState('');
  // const [cityPage, setCityPage] = useState(1);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [terms, setTerms] = useState(false);

  // const [stateValue, setStateValue] = useState({});
  // const [cityValue, setCityValue] = useState('');
  // const [loader, setLoader] = React.useState(false);
  // const [validZip, setValidZip] = React.useState(false);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [timeZoneId, setTimeZoneId] = useState(null);
  const [timeZone, setTimeZone] = useState(null);

  // Using the custom hook for address lookup
  // const {
  //   stateOptions,
  //   cityOptions,
  //   address: fetchedAddress,
  //   loader: addressLoader,
  //   validZip: addressValidZip,
  //   lat_lon,
  // } = useAddressLookup(zip);

  // useEffect(() => {
  //   if (addressValidZip) {
  //     // Assuming the first state and city are selected when valid ZIP is found
  //     if (stateOptions.length > 0) {
  //       setStateValue(stateOptions[0]);
  //       setState(stateOptions[0].value);
  //     }
  //     if (cityOptions.length > 0) {
  //       setCityValue(cityOptions[0]);
  //       setCity(cityOptions[0].value);
  //     }
  //     setStreet(fetchedAddress);
  //     setValidZip(true);
  //     setLoader(false);
  //   }
  // }, [addressValidZip, stateOptions, cityOptions, fetchedAddress]);

  const handleClose = () => {
    setTerms(false);
  };

  // const getCities = async () => {
  //   try {
  //     const cityRes = await axios.get(
  //       `${API_BASE_URL}/cities?name=${cityQuery}&page=${cityPage}`
  //     );

  //     const citiesArray = Object.values(cityRes.data.data).map((city) => ({
  //       value: city.id,
  //       label: city.name,
  //     }));

  //     if (cityPage === 1) {
  //       // setCityOptions(citiesArray);
  //     } else {
  //       // setCityOptions((prevOptions) => [...prevOptions, ...citiesArray]);
  //     }

  //     if (user.city_id) {
  //       setCityValue(
  //         citiesArray.find((option) => option.value === parseInt(user.city_id))
  //       );
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const getStates = async () => {
  //   try {
  //     const stateRes = await axios.get(`${API_BASE_URL}/states`);

  //     const statesArray = Object.values(stateRes.data.data).map((state) => ({
  //       value: state.id,
  //       label: state.name,
  //     }));
  //     // setStateOptions(statesArray);
  //     setStateValue(
  //       statesArray.find((option) => option.value === user.state_id)
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getTimeZones = async () => {
    try {
      const timeZoneRes = await axios.get(`${API_BASE_URL}/zones`);

      const timeZoneArray = Object.values(timeZoneRes.data.data).map(
        (timeZone) => ({
          id: timeZone.id,
          label: timeZone.zone_name,
        })
      );
      setTimeZoneOptions(timeZoneArray);
    } catch (error) {}
  };

  // const getCityStateByZip = async (zip) => {
  //   try {
  //     setLoader(true);
  //     const response = await axios.get(`${API_BASE_URL}/get-city-state/${zip}`);

  //     const { state_city, status, lat_lon, formatted_address } =
  //       response.data.data.data;

  //     if (status === 'OK') {
  //       const statesArray = [
  //         {
  //           value: state_city.id,
  //           label: state_city.name,
  //         },
  //       ];

  //       const citiesArray = Object.values(state_city.cities).map((city) => ({
  //         value: city.id,
  //         label: city.name,
  //       }));

  //       // setStateOptions(statesArray);
  //       // setCityOptions(citiesArray);
  //       setStateValue(statesArray[0]);
  //       setCityValue(citiesArray[0]);
  //       setState(statesArray[0].value);
  //       setCity(citiesArray[0].value);
  //       // setLongitude(lat_lon.longitude);
  //       // setLatitude(lat_lon.latitude);
  //       setStreet(formatted_address);
  //       setValidZip(true);
  //       setLoader(false);
  //       // setOpenState(true);
  //       // setOpenCity(true);
  //     } else {
  //       // setStateOptions([]);
  //       // setCityOptions([]);
  //       setLoader(false);
  //       setValidZip(false);
  //       // handleClick();
  //       console.error('No results found for the given ZIP code');
  //     }
  //   } catch (error) {
  //     // Handle error from the API request
  //     console.error('Error fetching data from Google Maps API:', error);
  //   }
  // };

  // const handleZipCodeChange = async (e) => {
  //   const newZip = e.target.value;
  //   setZip(newZip);
  //   // No need to call getCityStateByZip here, it's handled by the hook
  // };

  useEffect(() => {
    getTimeZones();
  }, []);

  // useEffect(() => {
  //   // getCities();
  // }, [cityQuery, cityPage]);

  // const handleCityInputChange = (event, newInputValue) => {
  //   setCityQuery(newInputValue);
  //   setCityPage(1);
  // };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  // const handleStreetChange = (event) => {
  //   setStreet(event.target.value);
  // };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const token = localStorage.getItem('auth_token');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNext = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      title,
      first_name: firstName,
      last_name: lastName,
      zip: userAddressData.zip,
      street: userAddressData.address,
      city_id: userAddressData.city_id,
      state_id: userAddressData.state_id,
      steps_completed: 1,
      time_zone_id: timeZoneId,
    };

    console.log('Save ===data', data);

    await axios
      .post(`${API_BASE_URL}/signup/profile/personal-details`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res) {
          dispatch(setUser(res.data.data));
          localStorage.setItem('user', JSON.stringify(res.data.data));
          setActiveStep(2);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    setIsLoading(false);
  };

  return (
    <div>
      <h4
        className="text-start mb-2 fw-semibold"
        style={{ fontSize: '1.25rem' }}
      >
        Personal Details
      </h4>
      <form className="w-100 mx-auto" onSubmit={handleNext}>
        <Grid container spacing={2} className="m-0">
          <Grid item xs={12} sm={6} md={4} className="mb-3">
            <FormControl variant="outlined" className="w-100">
              <TextField
                required
                className="w-100"
                label="Title"
                variant="outlined"
                value={title}
                onChange={handleTitleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className="mb-3">
            <FormControl variant="outlined" className="w-100">
              <TextField
                required
                className="w-100"
                label="First Name"
                variant="outlined"
                value={firstName}
                onChange={handleFirstNameChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className="mb-3">
            <FormControl variant="outlined" className="w-100">
              <TextField
                required
                className="w-100"
                label="Last Name"
                variant="outlined"
                value={lastName}
                onChange={handleLastNameChange}
              />
            </FormControl>
          </Grid>
          {/* <Col xs={12} sm={6} md={4} className="mb-3">
            <FormControl size="small" variant="outlined" className="w-100 m">
              <TextField
                required
                className="w-100"
                label="ZIP"
                size="small"
                variant="outlined"
                value={zip}
                onChange={handleZipCodeChange}
                onInput={(e) => {
                  if (e.target.value.length > 5) {
                    e.target.value = e.target.value.slice(0, 5);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="right">
                      {addressLoader ? (
                        <CircularProgress size="1rem" />
                      ) : addressValidZip ? (
                        <CheckIcon />
                      ) : zip.length >= 5 ? (
                        <ClearIcon onClick={(e) => {}} />
                      ) : null}
                    </InputAdornment>
                  ),
                  pattern: '^\\d{5}$',
                  title: 'ZIP code can be only 5-digit',
                  minLength: 5,
                  maxLength: 5,
                }}
              />
            </FormControl>
          </Col>
          <Col xs={12} sm={6} md={4} className="mb-3">
            <FormControl
              size="small"
              required
              variant="outlined"
              className="w-100"
            >
              <Autocomplete
                size="small"
                options={stateOptions ? stateOptions : []}
                getOptionLabel={(option) => (option.label ? option.label : '')}
                value={stateValue}
                onChange={(event, newValue) => {
                  setState(newValue ? newValue.value : '');
                  setStateValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    required
                    variant="outlined"
                    className="w-100"
                  />
                )}
              />
            </FormControl>
          </Col>

          <Col xs={12} sm={6} md={4} className="mb-3">
            <Autocomplete
              size="small"
              options={cityOptions ? cityOptions : []}
              getOptionLabel={(option) => (option.label ? option.label : '')}
              value={cityValue ? cityValue : ''}
              onChange={(event, newValue) => {
                setCity(newValue ? newValue.value : '');
                setCityValue(newValue);
              }}
              onInputChange={handleCityInputChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="City"
                  variant="outlined"
                  className="w-100 mb-2"
                />
              )}
              MenuProps={{
                onScroll: (e) => {
                  const target = e.target;
                  if (
                    target.scrollHeight - target.scrollTop ===
                    target.clientHeight
                  ) {
                    setCityPage((page) => page + 1);
                  }
                },
              }}
            />
          </Col>
          <Col xs={12} sm={6} md={4} className="mb-3">
            <FormControl size="small" variant="outlined" className="w-100 m">
              <TextField
                required
                className="w-100"
                label="Street"
                size="small"
                variant="outlined"
                value={street}
                onChange={handleStreetChange}
              />
            </FormControl>
          </Col> */}
          <GeneralAddress
            defaultValues={userAddressData}
            labels={{
              zip: 'Zip Code',
              state: 'State',
              city: 'City',
              address: 'Address',
            }}
            names={{
              zip: 'zip',
              state: 'state_id',
              city: 'city_id',
              address: 'address',
            }}
            updateAddressData={updateUserAddress}
            gridProps={{ xs: 12, sm: 6, md: 4, className: 'mb-3' }}
          />

          <Grid item xs={12} sm={6} md={4} className="mb-3">
            <FormControl required variant="outlined" className="w-100">
              <Autocomplete
                options={timeZoneOptions ? timeZoneOptions : []}
                getOptionLabel={(option) => (option.label ? option.label : '')}
                value={timeZone}
                onChange={(event, newValue) => {
                  setTimeZoneId(newValue.id);
                  setTimeZone(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Time Zone"
                    required
                    variant="outlined"
                    className="w-100"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>

        {user && user.role_type === 'professional' && (
          <Row className="mb-3">
            <Col xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                    color="primary"
                  />
                }
                label={
                  <span>
                    By creating a Mayday Dental Staffing Account, you agree to
                    Mayday Dental Staffing's{' '}
                    <span
                      onClick={() => {
                        setTerms(true);
                      }}
                      className="text-underline"
                      style={{ textDecoration: 'underline', color: '#2561B0' }}
                    >
                      Terms And Conditions
                    </span>
                  </span>
                }
              />
            </Col>
          </Row>
        )}

        <Row className="my-0 my-md-4">
          <Col className="mt-3 mt-md-0 ps-2" xs={12} md={3} lg={2}>
            <Button
              variant="outlined"
              color="primary"
              style={{ height: '2.5rem', width: '100%' }}
              sx={{
                borderRadius: '4px',
                color: '#2561B0',
                textTransform: 'none',
              }}
              onClick={() => {
                dispatch(setRoleType('general'));
                navigate('/selectRole');
              }}
            >
              Back
            </Button>
          </Col>
          <Col className="my-3 my-md-0 ps-2" xs={12} md={4} lg={3} xl={2}>
            <Button
              type="submit"
              variant="contained"
              style={{ height: '2.5rem', width: '100%' }}
              color="primary"
              sx={{
                borderRadius: '4px',
                background: '#2561B0',
                textTransform: 'none',
              }}
              disabled={
                (user && user.role_type === 'professional' && !isChecked) ||
                !userAddressData.zip ||
                !userAddressData.city_id ||
                !userAddressData.state_id ||
                !firstName ||
                !lastName ||
                !userAddressData.address
                // || !cityValue
              }
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Next Step'
              )}
            </Button>
          </Col>
        </Row>
      </form>

      <Dialog
        open={terms}
        onClose={() => {
          setTerms(false);
        }}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <DialogContent>
          <h4 className="font-weight-bold text-black mb-4">
            Terms and Conditions
          </h4>
          <p className="mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh magnis
            gravida nunc sagittis, odio egestas. Neque risus id varius et
            mattis. Eget gravida urna pellentesque non lobortis bibendum
            consequat. Nisl cursus in euismod tellus ut sapien non. Sed amet
            nec, nunc eget. Dictum quam ipsum nunc, amet. Habitasse egestas
            fermentum nunc scelerisque cursus convallis in aliquam. Integer cras
            rhoncus, nibh dui tellus est et diam. Sit lectus id id sagittis
            turpis. Sit sit donec tellus lorem. Quam viverra dictum amet, arcu.
            Vulputate tellus pellentesque nam eget sagittis urna scelerisque.
            Amet elit justo, praesent et sed in cursus turpis arcu. Quisque
            justo, pretium ullamcorper in sem fermentum. Sit faucibus blandit
            tortor lorem ac ac massa sit mattis. Tempus varius faucibus maecenas
            est, sed risus, tellus egestas. Nunc egestas bibendum adipiscing
            consectetur malesuada nunc vel commodo. Accumsan, suspendisse
            fringilla a gravida sit aliquam enim eu massa. Ac diam felis
            elementum nisi, massa ut ut nisi. Interdum tempus velit id diam
            volutpat. <br /> <br /> Velit sit tellus urna semper enim urna amet
            dictumst nullam. Sed pellentesque egestas pharetra et ultricies
            feugiat pulvinar. Neque mauris elementum gravida scelerisque magna
            mi. Duis molestie arcu, ut scelerisque volutpat. Tortor sed sapien
            turpis sem praesent morbi est sit. Ornare etiam aliquet at sed in.
            Luctus vitae consectetur nulla sed consectetur tempus, tempor. Arcu,
            nec, convallis et orci nisl. Scelerisque diam varius sollicitudin
            non sit. Fusce vitae vel lectus vulputate orci. Eget ornare aliquet
            turpis massa tortor, platea blandit elementum commodo. A, amet sed
            tortor a, ac ridiculus dictum amet. Maecenas nulla mi volutpat
            tellus tellus amet, ac aenean ligula. Urna, ipsum pretium, id turpis
            ultrices lacus, congue. Convallis metus purus dolor accumsan tortor.
            In orci turpis interdum molestie adipiscing dictumst. Aliquam
            malesuada enim arcu egestas elit. Mi purus, mi fusce eget. Tellus
            arcu, diam pharetra vitae urna mauris, parturient nibh neque.
          </p>
        </DialogContent>
        <DialogActions>
          <div className="d-flex justify-content-end">
            <Button variant="secondary" className="mx-2" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Ok
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
