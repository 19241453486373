import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import changePasswordImg from '../../../assets/images/change-password.png';
import axios from 'axios';
import { API_BASE_URL } from '../../../config/constants';

const ChangePasswordDialog = ({ open, handleClose }) => {
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    c_password: Yup.string().required('Current password is required'),
    n_password: Yup.string()
      .required('New password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('n_password'), null], 'Passwords must match')
      .required('Confirm password is required'),
  });

  const handleChangePassword = async (values, { setSubmitting }) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/owner/profile/update-password`,
        {
          current_password: values.c_password,
          password: values.n_password,
          password_confirmation: values.confirm_password,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        }
      );
      setAlert({
        message: 'Password changed successfully!',
        severity: 'success',
      });
      // handleClose(); // Close the dialog on success
    } catch (error) {
      setAlert({
        message: 'Error changing password. Please try again.',
        severity: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ height: 'auto' }}
    >
      <Box
        sx={{
          height: '120px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          backgroundColor: 'rgb(37, 97, 176)',
        }}
      >
        <Box
          component="img"
          src={changePasswordImg}
          alt="Change Email"
          sx={{
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </Box>
      <DialogTitle id="alert-dialog-title" className="pb-0 fw-bold">
        Change Password
      </DialogTitle>
      <Formik
        initialValues={{
          c_password: '',
          n_password: '',
          confirm_password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleChangePassword}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogContent>
              <small className="text-muted d-inline-block mb-2">
                Fields marked "*" are mandatory
              </small>
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 'auto', width: '100%' },
                }}
                noValidate
                autoComplete="off"
                className="d-flex flex-column justify-content-center m-auto w-100"
              >
                {alert.message && (
                  <Alert
                    severity={alert.severity}
                    // style={{ margin: '10px 0 0' }}
                    className="mb-3"
                  >
                    {alert.message}
                  </Alert>
                )}
                <Field
                  as={TextField}
                  id="c_password"
                  label="Current Password"
                  type={showCurrentPassword ? 'text' : 'password'}
                  name="c_password"
                  required
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={() =>
                          setShowCurrentPassword(!showCurrentPassword)
                        }
                      >
                        {showCurrentPassword ? (
                          <VisibilityOutlined />
                        ) : (
                          <VisibilityOffOutlined />
                        )}
                      </Button>
                    ),
                  }}
                />
                <ErrorMessage
                  name="c_password"
                  component="small"
                  style={{ color: 'red' }}
                />

                <Alert severity="info" className='my-2'>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>
                      Password Requirements
                    </span>
                  </div>
                  <ul style={{ marginTop: '6px', listStyleType: '-' }}>
                    <li>Upper case letters (ABC)</li>
                    <li style={{ marginTop: '2px', listStyleType: '-' }}>
                      Lower case letters (abc)
                    </li>
                    <li style={{ marginTop: '2px' }}>Numbers (123)</li>
                    <li style={{ marginTop: '2px' }}>Minimum characters 8</li>
                  </ul>
                </Alert>

                <Field
                  as={TextField}
                  id="n_password"
                  label="New Password"
                  type={showNewPassword ? 'text' : 'password'}
                  name="n_password"
                  required
                  style={{ marginTop: '10px' }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      >
                        {showNewPassword ? (
                          <VisibilityOutlined />
                        ) : (
                          <VisibilityOffOutlined />
                        )}
                      </Button>
                    ),
                  }}
                />
                <ErrorMessage
                  name="n_password"
                  component="small"
                  style={{ color: 'red' }}
                />

                <Field
                  as={TextField}
                  id="confirm_password"
                  label="Confirm Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  name="confirm_password"
                  required
                  style={{ marginTop: '10px' }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <VisibilityOutlined />
                        ) : (
                          <VisibilityOffOutlined />
                        )}
                      </Button>
                    ),
                  }}
                />
                <ErrorMessage
                  name="confirm_password"
                  component="small"
                  style={{ color: 'red' }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" disabled={isSubmitting} autoFocus>
                CONFIRM
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ChangePasswordDialog;
