import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { API_BASE_URL } from '../../config/constants';
import useAddressLookup from '../../hooks/useAddressLookup';
import { setUser } from '../../redux/slices/userSlice';
import PhoneNumberInput from '../General/PhoneNumberInput';
import GeneralAddress from '../../pages/GeneralAddress';

//Step 2
export default function CompanyDetails({ setActiveStep }) {
  const user6 = localStorage.getItem('user');
  const user = JSON.parse(user6);
  // const [billingZip, setBillingZip] = useState(
  //   user.user_billing_addresses[0]?.zip || ''
  // );
  // const [billingCity, setBillingCity] = useState(
  //   user.user_billing_addresses[0]?.city_id || ''
  // );
  // const [billingStreet, setBillingStreet] = useState(
  //   user.user_billing_addresses[0]?.street || ''
  // );
  // const [billingState, setBillingState] = useState(
  //   user.user_billing_addresses[0]?.state_id || ''
  // );
  const [billingAddressData, setBillingAddressData] = useState({
    zip:  user.user_billing_addresses[0]?.zip || '',
    state_id:  user.user_billing_addresses[0]?.state_id || '',
    city_id:  user.user_billing_addresses[0]?.city_id || '',
    address: user.user_billing_addresses[0]?.street || '',
  });
  const updateBillingAddressData = (field, value) => {
    setBillingAddressData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };


  const [companyName, setCompanyName] = useState(user.companies[0]?.name || '');
  const [officeManager, setOfficeManager] = useState(
    user.companies[0]?.office_manager || ''
  );
  const [notes, setNotes] = useState(user.companies[0]?.notes || '');
  const [contactPhone, setContactPhone] = useState(
    user.companies[0]?.contact_phone || ''
  );
  const [secondEmail, setSecondEmail] = useState(
    user.companies[0]?.second_email || ''
  );
  const [companyWeb, setCompanyWeb] = useState(
    user.companies[0]?.website || ''
  );
  const [docCell, setDocCell] = useState(
    user.companies[0]?.doctor_cell || null
  );
  const [companySpeciality, setCompanySpeciality] = useState(
    user.companies[0]?.speciality_id || ''
  );
  const [specOptions, setSpecOptions] = useState([]);
  const [fax, setFax] = useState(user.companies[0]?.fax || '');
  const [afterHoursPhone, setAfterHoursPhone] = useState(
    user.companies[0]?.after_work_phone || ''
  );
  const [pracMngSft, setPracMngSft] = useState(
    user.companies[0]?.practice_management_software || ''
  );

  // const [stateOptions, setStateOptions] = useState([]);
  // const [cityOptions, setCityOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [cityQuery, setCityQuery] = useState('');
  // const [cityPage, setCityPage] = useState(1);

  // const [billingStateValue, setBillingStateValue] = useState(null);
  // const [billingCityValue, setBillingCityValue] = useState(null);

  // const {
  //   stateOptions,
  //   cityOptions,
  //   address: fetchedAddress,
  //   loader: addressLoader,
  //   validZip: addressValidZip,
  // } = useAddressLookup(billingZip);

  const [terms, setTerms] = useState(false);
  const handleClose = () => {
    setTerms(false);
  };

  // const getStates = async () => {
  //   try {
  //     const stateRes = await axios.get(`${API_BASE_URL}/states`);

  //     const statesArray = Object.values(stateRes.data.data).map((state) => ({
  //       value: state.id,
  //       label: state.name,
  //     }));
  //     // setStateOptions(statesArray);
  //     if (user?.user_billing_addresses[0]?.state_id) {
  //       const billingStateValue = statesArray.find(
  //         (option) => option.value === billingState
  //       );
  //       setBillingState(billingStateValue);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const getCities = async () => {
  //   try {
  //     const cityRes = await axios.get(
  //       `${API_BASE_URL}/cities?name=${cityQuery}&page=${cityPage}`
  //     );

  //     const citiesArray = Object.values(cityRes.data.data).map((city) => ({
  //       value: city.id,
  //       label: city.name,
  //     }));

  //     if (cityPage === 1) {
  //       // setCityOptions(citiesArray);
  //     } else {
  //       // setCityOptions((prevOptions) => [...prevOptions, ...citiesArray]);
  //     }

  //     if (user?.user_billing_addresses[0]?.city_id) {
  //       const billingCity = citiesArray.find(
  //         (option) => option.value === parseInt(billingCity)
  //       );
  //       setBillingCity(billingCity);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    const getSpecialties = async () => {
      try {
        const specRes = await axios.get(`${API_BASE_URL}/specialities`);

        const specsArray = Object.values(specRes.data.data).map((spec) => ({
          value: spec.id,
          label: spec.name,
        }));
        setSpecOptions(specsArray);
      } catch (error) {
        console.error(error);
      }
    };
    // getStates();
    getSpecialties();
  }, []);

  // useEffect(() => {
  //   getCities();
  // }, [cityQuery, cityPage]);

  // useEffect(() => {
  //   if (user?.user_billing_addresses[0]?.state_id) {
  //     const billingStateValue = stateOptions.find(
  //       (option) => option.value === billingState
  //     );
  //     setBillingStateValue(billingStateValue);
  //   }
  // }, [billingState, stateOptions, user.user_billing_addresses]);

  // useEffect(() => {
  //   if (user?.user_billing_addresses[0]?.city_id) {
  //     const billingCityValue = cityOptions.find(
  //       (option) => option.value === parseInt(billingCity)
  //     );
  //     setBillingCityValue(billingCityValue);
  //   }
  // }, [billingCity, cityOptions, user.user_billing_addresses]);

  // const handleCityInputChange = (event, newInputValue) => {
  //   setCityQuery(newInputValue);
  //   setCityPage(1);
  // };

  const handleInputChange = (e, setState) => {
    setState(e.target.value);
  };

  const dispatch = useDispatch();
  const token = localStorage.getItem('auth_token');

  const handleSubmit = async (e) => {
    setIsLoading(true);
    await axios
      .post(
        // `${API_BASE_URL}/signup/profile/company-details?zip=${billingZip}&street=${billingStreet}&city_id=${billingCity.value}&state_id=${billingState.value}&company_name=${companyName}&speciality_id=${companySpeciality}&contact_phone=${contactPhone}&office_manager=${officeManager}&second_email=${secondEmail}&doctor_cell=${docCell}&after_work_phone=${afterHoursPhone}&fax=${fax}&practice_management_software=${pracMngSft}&website=${companyWeb}&notes=${notes}&steps_completed=2`,
        `${API_BASE_URL}/signup/profile/company-details?zip=${billingAddressData.zip}&street=${billingAddressData.address}&city_id=${billingAddressData.city_id}&state_id=${billingAddressData.state_id}&company_name=${companyName}&speciality_id=${companySpeciality}&contact_phone=${contactPhone}&office_manager=${officeManager}&second_email=${secondEmail}&doctor_cell=${docCell}&after_work_phone=${afterHoursPhone}&fax=${fax}&practice_management_software=${pracMngSft}&website=${companyWeb}&notes=${notes}&steps_completed=2`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(setUser(res.data.data));
          localStorage.setItem('user', JSON.stringify(res.data.data));
          setActiveStep((state) => state + 1);
        }
      })
      .catch((error) => {
        alert('Error');
        console.error('Error:', error);
      });
    setIsLoading(false);
  };

  // useEffect(() => {
  //   if (addressValidZip) {
  //     if (stateOptions.length > 0) {
  //       console.log('stateOptions', stateOptions);
  //       setBillingState(stateOptions[0].value);
  //     }
  //     if (cityOptions.length > 0) {
  //       setBillingCity(cityOptions[0].value);
  //     }
  //     setBillingStreet(fetchedAddress);
  //   }
  // }, [addressValidZip, stateOptions, cityOptions, fetchedAddress]);

  // useEffect(() => {
  //   if (addressValidZip) {
  //     // Assuming the first state and city are selected when valid ZIP is found
  //     if (stateOptions.length > 0) {
  //       setBillingState(stateOptions[0]);
  //     }
  //     if (cityOptions.length > 0) {
  //       setBillingCity(cityOptions[0]);
  //     }
  //     setBillingStreet(fetchedAddress);
  //   }
  // }, [addressValidZip, stateOptions, cityOptions, fetchedAddress]);

  // const handleZipCodeChange = (e) => {
  //   const newZip = e.target.value;
  //   console.log('newZip', newZip);
  //   setBillingZip(newZip);
  // };

  // const renderAutocomplete = (
  //   options,
  //   value,
  //   setValue,
  //   label,
  //   onInputChange
  // ) => (
  //   <Autocomplete
  //     options={options}
  //     getOptionLabel={(option) => option.label || ''}
  //     value={value || null}
  //     onChange={(event, newValue) => {
  //       setValue(newValue);
  //     }}
  //     onInputChange={onInputChange}
  //     renderInput={(params) => (
  //       <TextField
  //         {...params}
  //         required
  //         label={label}
  //         variant="outlined"
  //         className="w-100"
  //       />
  //     )}
  //     MenuProps={{
  //       onScroll: (e) => {
  //         const target = e.target;
  //         if (target.scrollHeight - target.scrollTop === target.clientHeight) {
  //           setCityPage((page) => page + 1);
  //         }
  //       },
  //     }}
  //   />
  // );

  return (
    <div className="mx-auto mx-lg-0">
      <h4
        className="text-start mb-4 fw-semibold"
        style={{ fontSize: '1.25rem' }}
      >
        Billing Address Information
      </h4>
      <form
        className=""
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {/* <Row>
          <Col xs={12} md={6} lg={4} xl={3} className="mb-3">
            <FormControl required variant="outlined" className="w-100">
              <TextField
               
                required
                className="w-100"
                label="Billing ZIP"
                variant="outlined"
                value={billingZip}
                onChange={handleZipCodeChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="right">
                      {addressLoader ? (
                        <CircularProgress size="1rem" />
                      ) : addressValidZip ? (
                        <CheckIcon />
                      ) : billingZip.length >= 5 ? (
                        <ClearIcon onClick={(e) => {}} />
                      ) : null}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6} lg={4} xl={3} className="mb-3">
            <FormControl
              required
              variant="outlined"
              className="w-100"
              
            >
              {renderAutocomplete(
                stateOptions,
                billingState,
                setBillingState,
                'Billing State'
                // handleCityInputChange
              )}
            </FormControl>
          </Col>

          <Col xs={12} md={6} lg={4} xl={3} className="mb-3">
            <FormControl
              required
              variant="outlined"
              className="w-100"
             
            >
              {renderAutocomplete(
                cityOptions,
                billingCity,
                setBillingCity,
                'Billing City'
                // handleCityInputChange
              )}
            </FormControl>
          </Col>
          <Col xs={12} md={6} lg={4} xl={3} className="mb-3">
            <FormControl required variant="outlined" className="w-100">
              <TextField
               
                required
                className="w-100"
                label="Billing Street"
                variant="outlined"
                value={billingStreet}
                onChange={(e) => setBillingStreet(e.target.value)}
              />
            </FormControl>
          </Col>
        </Row> */}
        <Grid container spacing={2}>
          <GeneralAddress
            defaultValues={billingAddressData}
            labels={{
              zip: 'Zip Code',
              state: 'State',
              city: 'City',
              address: 'Address',
            }}
            names={{
              zip: 'zip',
              state: 'state_id',
              city: 'city_id',
              address: 'address',
            }}
            updateAddressData={updateBillingAddressData}
            gridProps={{ xs: 12, sm: 6, md: 4, className: 'mb-3' }}
          />
        </Grid>
        <h4
          className="text-start my-4 fw-semibold"
          style={{ fontSize: '1.25rem' }}
        >
          Company Details
        </h4>
        <Row>
          <Col xs={12} md={6} lg={4} xl={3}>
            <FormControl variant="outlined" className="w-100">
              <TextField
                required
                className="w-100"
                label="Company Name"
                variant="outlined"
                sx={{ mb: 3 }}
                value={companyName}
                onChange={(e) => handleInputChange(e, setCompanyName)}
              />
            </FormControl>
            <FormControl variant="outlined" className="w-100">
              <PhoneNumberInput
                value={contactPhone}
                onChange={(phone) => {
                  setContactPhone(phone);
                }}
                label="Contact Phone"
                sx={{ mb: 3 }}
                isRequired
              />
            </FormControl>
            <FormControl variant="outlined" className="w-100">
              <TextField
                className="w-100"
                label="Company Website"
                variant="outlined"
                sx={{ mb: 3 }}
                value={companyWeb}
                onChange={(e) => handleInputChange(e, setCompanyWeb)}
              />
            </FormControl>
            <FormControl variant="outlined" className="w-100">
              <InputLabel id="state-select-label">
                Company Speciality
              </InputLabel>
              <Select
                labelId="city-select-label"
                id="city-select"
                value={companySpeciality}
                sx={{ mb: 3 }}
                onChange={(e) => setCompanySpeciality(e.target.value)}
                label="Company Speciality"
              >
                {specOptions.length > 0 &&
                  specOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className="w-100">
              <PhoneNumberInput
                value={afterHoursPhone}
                onChange={(phone) =>
                  handleInputChange(
                    { target: { value: phone } },
                    setAfterHoursPhone
                  )
                }
                label="After working hours phone number"
                isRequired
                sx={{ mb: 3 }}
              />
            </FormControl>
          </Col>

          <Col xs={12} md={6} lg={4} xl={3}>
            <FormControl variant="outlined" className="w-100">
              <TextField
                className="w-100"
                label="Office Manager"
                variant="outlined"
                sx={{ mb: 3 }}
                value={officeManager}
                onChange={(e) => handleInputChange(e, setOfficeManager)}
              />
            </FormControl>
            <FormControl variant="outlined" className="w-100">
              <TextField
                className="w-100"
                type="email"
                label="Alternative Email"
                variant="outlined"
                sx={{ mb: 3 }}
                value={secondEmail}
                onChange={(e) => handleInputChange(e, setSecondEmail)}
              />
            </FormControl>
            <FormControl variant="outlined" className="w-100">
              <PhoneNumberInput
                value={docCell}
                onChange={(phone) =>
                  handleInputChange({ target: { value: phone } }, setDocCell)
                }
                label="Doctor Cell"
                required
                sx={{ mb: 3 }}
              />
            </FormControl>
            <FormControl variant="outlined" className="w-100">
              <InputMask
                mask="(999) 999 - 9999"
                maskChar="_"
                value={fax}
                onChange={(e) => handleInputChange(e, setFax)}
              >
                {(inputProps) => (
                  <TextField
                    className="w-100"
                    label="Fax"
                    variant="outlined"
                    sx={{ mb: 3 }}
                    inputProps={{ ...inputProps, type: 'tel' }}
                  />
                )}
              </InputMask>
            </FormControl>
            <FormControl variant="outlined" className="w-100">
              <TextField
                required
                className="w-100"
                label="Practice Management Software"
                variant="outlined"
                sx={{ mb: 3 }}
                value={pracMngSft}
                onChange={(e) => handleInputChange(e, setPracMngSft)}
              />
            </FormControl>
          </Col>
          <Col className="pb-2" style={{ height: '100%' }}>
            <FormControl
              variant="outlined"
              className="w-100"
              style={{ height: '100%' }}
            >
              <TextField
                className="w-100"
                fullWidth
                label="Notes"
                placeholder="2000 characters"
                variant="outlined"
                multiline
                rows={15}
                value={notes}
                onChange={(e) => handleInputChange(e, setNotes)}
              />
            </FormControl>
          </Col>
        </Row>

        {user && user.role_type === 'owner' && (
          <div className="mb-4">
            <span>
              By creating a Mayday Dental Staffing Account, you agree to Mayday
              Dental Staffing's{' '}
              <span
                onClick={() => {
                  setTerms(true);
                }}
                className="text-underline"
                style={{
                  textDecoration: 'underline',
                  color: '#2561B0',
                  cursor: 'pointer',
                }}
              >
                Terms And Conditions
              </span>
            </span>
          </div>
        )}

        <Row className="my-0 my-md-4">
          <Col className="my-1 mt-3 my-md-0 ps-2" xs={12} md={3}>
            <Button
              className="w-100 w-md-75"
              variant="outlined"
              color="primary"
              style={{ height: '2.5rem' }}
              sx={{
                borderRadius: '4px',
                boxShadow: 'none',
                color: '#2561B0',
                textTransform: 'none',
              }}
              onClick={() => {
                setActiveStep((state) => state - 1);
              }}
            >
              Back
            </Button>
          </Col>
          <Col className="my-1 mt-3 my-md-0 ps-2" xs={12} md={3}>
            <Button
              className="w-100"
              type="submit"
              variant="contained"
              style={{ height: '2.5rem' }}
              color="primary"
              sx={{
                borderRadius: '4px',
                boxShadow: 'none',
                background: '#2561B0',
                textTransform: 'none',
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Next Step'
              )}
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
}
