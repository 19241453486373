import { Person2Outlined } from '@mui/icons-material';
// import CheckIcon from '@mui/icons-material/Check';
// import ClearIcon from '@mui/icons-material/Clear';
import {
  // Autocomplete,
  Box,
  Button,
  Checkbox,
  // CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  // InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfilePicture from '../../../components/General/ProfilePicture';
import SuccessModal from '../../../components/General/SuccessModal';
import Layout from '../../../components/Layout';
import { API_BASE_URL } from '../../../config/constants';
// import useAddressFields from '../../../hooks/useAddress';
import { selectUser, setUser } from '../../../redux/slices/userSlice';
import GeneralAddress from '../../GeneralAddress';
import ChangeEmailDialog from '../../Professional/Profile/ChangeEmailDialog';
import ChangePasswordDialog from '../../Professional/Profile/ChangePasswordDialog';
import PhoneNumberInput from '../../../components/General/PhoneNumberInput';

const OwnerProfile = () => {
  const [stateOptions, setStateOptions] = useState([]);
  const [billingStateValue, setBillingStateValue] = useState(null);
  const [stateValue, setStateValue] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [billingCityOptions, setBillingCityOptions] = useState([]);
  const [cityValue, setCityValue] = useState(null);
  const [cityQuery, setCityQuery] = useState('');
  const [cityPage, setCityPage] = useState(1);
  const [billingCityQuery, setBillingCityQuery] = useState('');
  const [billingCityPage, setBillingCityPage] = useState(1);
  const [billingCityValue, setBillingCityValue] = useState(null);
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const [isPasswordOpen, setIsPasswordOpen] = useState(false);
  // const [changeEmail, setChangeEmail] = useState({
  //   password: '',
  //   email: '',
  // });
  const [changePassword, setChangePassword] = useState({
    c_password: '',
    n_password: '',
    confirm_password: '',
  });

  const [profileSubmit, setProfileSubmit] = useState(false);
  const [citiesLoaded, setCitiesLoaded] = useState(false);
  const [statesLoaded, setStatesLoaded] = useState(false);
  const [allStates, setAllStates] = useState([]);

  const currentUser = useSelector(selectUser);

  const dispatch = useDispatch();
  const [website, setWebsite] = useState(
    currentUser && currentUser.website ? currentUser.website : null
  );
  const [title, setTitle] = useState(
    currentUser && currentUser.title ? currentUser.title : ''
  );
  const [firstName, setFirstName] = useState(
    currentUser && currentUser.first_name ? currentUser.first_name : ''
  );
  const [lastName, setLastName] = useState(
    currentUser && currentUser.last_name ? currentUser.last_name : ''
  );
  // const [address, setAddress] = useState(
  //   currentUser && currentUser.street ? currentUser.street : ''
  // );
  // const [cityId, setCityId] = useState(
  //   currentUser && currentUser.city_id ? currentUser.city_id : ''
  // );
  // const [stateId, setStateId] = useState(
  //   currentUser && currentUser.state_id ? currentUser.state_id : ''
  // );
  // const [zip, setZip] = useState(
  //   currentUser && currentUser.zip ? currentUser.zip : ''
  // );
  const [timeZoneId, setTimeZoneId] = useState(
    currentUser && currentUser.time_zone_id ? currentUser.time_zone_id : null
  );

  const [billingAddressSameAsPersonal, setBillingAddressSameAsPersonal] =
    useState(
      currentUser && currentUser.is_billing_address_same
        ? currentUser.is_billing_address_same
        : false
    );

  const [billingAddressData, setBillingAddressData] = useState({
    zip: currentUser?.user_billing_addresses?.[0]?.zip || '',
    state_id: currentUser?.user_billing_addresses?.[0]?.state_id || null,
    city_id: currentUser?.user_billing_addresses?.[0]?.city_id || null,
    address: currentUser?.user_billing_addresses?.[0]?.street || '',
  });

  const [userAddressData, setUserAddressData] = useState({
    zip: currentUser?.zip || '',
    state_id: currentUser?.state_id || null,
    city_id: currentUser?.city_id || null,
    address: currentUser?.street || '',
  });

  // const [billingAddress, setBillingAddress] = useState(
  //   currentUser && currentUser.user_billing_addresses
  //     ? currentUser.user_billing_addresses[0]?.street
  //     : ''
  // );

  // const [billingCityId, setBillingCityId] = useState(
  //   currentUser && currentUser.user_billing_addresses
  //     ? currentUser.user_billing_addresses[0]?.city_id
  //     : ''
  // );

  // const [billingStateId, setBillingStateId] = useState(
  //   currentUser && currentUser.user_billing_addresses
  //     ? currentUser.user_billing_addresses[0]?.state_id
  //     : ''
  // );

  // const [billingZip, setBillingZip] = useState(
  //   currentUser && currentUser.user_billing_addresses
  //     ? currentUser.user_billing_addresses[0]?.zip
  //     : ''
  // );

  const [companyName, setCompanyName] = useState(
    currentUser && currentUser.companies ? currentUser.companies[0]?.name : ''
  );

  const [officeManager, setOfficeManager] = useState(
    currentUser && currentUser.companies
      ? currentUser.companies[0]?.office_manager
      : ''
  );

  const [contactPhone, setContactPhone] = useState(
    currentUser && currentUser.companies
      ? currentUser.companies[0]?.contact_phone
      : ''
  );

  const [secondEmail, setSecondEmail] = useState(
    currentUser && currentUser.companies
      ? currentUser.companies[0]?.second_email
      : ''
  );

  const [practiceManagementSoftware, setPracticeManagementSoftware] = useState(
    currentUser && currentUser.companies
      ? currentUser.companies[0]?.practice_management_software
      : ''
  );

  const [companyWebsite, setCompanyWebsite] = useState(
    currentUser && currentUser.companies
      ? currentUser.companies[0]?.company_website
      : ''
  );

  const [doctorCell, setDoctorCell] = useState(
    currentUser && currentUser.companies
      ? currentUser.companies[0]?.doctor_cell
      : ''
  );

  // const [companySpecialtyId, setCompanySpecialtyId] = useState(
  //   currentUser && currentUser.companies ? currentUser.companies[0]?.speciality_id : ''
  // );

  const [companySpecialtyId, setCompanySpecialtyId] = useState(null);

  const [fax, setFax] = useState(
    currentUser && currentUser.companies ? currentUser.companies[0]?.fax : ''
  );

  const [afterWorkingHoursPhone, setAfterWorkingHoursPhone] = useState(
    currentUser && currentUser.companies
      ? currentUser.companies[0]?.after_work_phone
      : null
  );

  const [uploadFile, setUploadFile] = useState(null);

  const isInitialLoad = useRef(true);

  // const [changeEmailResponse, setChangeEmailResponse] = useState('');

  const getTimeZones = async () => {
    try {
      const timeZoneRes = await axios.get(`${API_BASE_URL}/zones`);

      const timeZoneArray = Object.values(timeZoneRes.data.data).map(
        (timeZone) => ({
          id: timeZone.id,
          label: timeZone.zone_name,
        })
      );
      setTimeZoneOptions(timeZoneArray);
    } catch (error) {}
  };

  const getStates = async () => {
    try {
      const stateRes = await axios.get(`${API_BASE_URL}/states`);
      const statesArray = Object.values(stateRes.data.data).map((state) => ({
        value: state.id,
        label: state.name,
      }));
      setAllStates(statesArray);
      setStatesLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };

  const getSpecialities = async () => {
    try {
      const specialityRes = await axios.get(`${API_BASE_URL}/specialities`);

      const specialitiesArray = Object.values(specialityRes.data.data).map(
        (speciality) => ({
          id: speciality.id,
          label: speciality.name,
        })
      );

      setSpecialityOptions(specialitiesArray);
      let company_speciality_id =
        currentUser && currentUser.companies
          ? currentUser.companies[0]?.speciality_id
          : null;
      let speciality_id = specialitiesArray.find((speciality) => {
        return speciality.id === company_speciality_id;
      });

      if (speciality_id !== undefined) {
        setCompanySpecialtyId(speciality_id.id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCities = async (stateId = null, cityId = null) => {
    console.log('getCities called with:', stateId, cityId);
    try {
      const cityRes = await axios.get(
        `${API_BASE_URL}/cities?name=${cityQuery}&page=${cityPage}${stateId ? `&state_id=${stateId}` : ''}${cityId ? `&id=${cityId}` : ''}`
      );

      const citiesArray = Object.values(cityRes.data.data).map((city) => ({
        value: city.id,
        label: city.name,
      }));

      // Always set city options regardless of the page number
      setCityOptions((prevOptions) => {
        if (cityPage === 1) {
          return citiesArray; // Replace options on the first page
        }
        return [...prevOptions, ...citiesArray]; // Append on subsequent pages
      });

      // Set cityValue only if cities have not been loaded yet
      if (!citiesLoaded) {
        const cityValue = citiesArray.find(
          (option) => option.value === parseInt(currentUser.city_id)
        );
        setCityValue(cityValue || null);
        setCitiesLoaded(true); // Mark cities as loaded
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const getBillingCities = async () => {
  //   try {
  //     const cityRes = await axios.get(
  //       `${API_BASE_URL}/cities?name=${billingCityQuery}&page=${billingCityPage}`
  //     );

  //     const citiesArray = Object.values(cityRes.data.data).map((city) => ({
  //       value: city.id,
  //       label: city.name,
  //     }));

  //     if (cityPage === 1) {
  //       setBillingCityOptions(citiesArray);
  //     } else {
  //       setBillingCityOptions((prevOptions) => [
  //         ...prevOptions,
  //         ...citiesArray,
  //       ]);
  //     }

  //     if (currentUser?.user_billing_addresses[0]?.city_id) {
  //       const billingCityValue = citiesArray.find(
  //         (option) => option.value === parseInt(billingCityId)
  //       );
  //       setBillingCityValue(billingCityValue);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    // getBillingCities();
  }, [billingCityQuery, billingCityPage]);

  // useEffect(() => {
  //   getCities(stateId, cityId);
  // }, [stateId, cityId, cityQuery, cityPage]);

  useEffect(() => {
    // getStates();
    getTimeZones();
    getSpecialities();
  }, []);

  const handleCityInputChange = (event, newInputValue) => {
    // if (newInputValue === cityValue?.label) return;

    setCityQuery(newInputValue);
    setCityPage(1);
  };

  const handleBillingCityInputChange = (event, newInputValue) => {
    setBillingCityQuery(newInputValue);
    setBillingCityPage(1);
  };

  // const handleChangeEmail = async () => {
  //   try {
  //     const res = await axios.put(
  //       `${API_BASE_URL}/owner/profile/initiate-email-change`,
  //       {
  //         current_password: changeEmail.password,
  //         email: changeEmail.email,
  //       },
  //       {
  //         headers: {
  //           method: 'PUT',
  //           Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
  //         },
  //       }
  //     );
  //     console.log(res);
  //     // Reset error message on success
  //     setChangeEmailResponse('');
  //     // const data = {
  //     //   ...currentUser,
  //     //   email: changeEmail.email,
  //     // };
  //     // setUser(data);
  //     // setIsEmailOpen(false);
  //   } catch (error) {
  //     let errorMessage = 'An unknown error occurred.'; // Default error message

  //     if (error.response && error.response.data.message) {
  //       const { fields, description } = error.response.data.message;

  //       if (fields && fields.length > 0) {
  //         // If fields are present, join them into a single message
  //         errorMessage = fields.join(', ');
  //       } else if (description) {
  //         // If fields are not present, use the description
  //         errorMessage = description;
  //       }
  //     }

  //     setChangeEmailResponse(errorMessage); // Set the error message state
  //   }
  // };

  const handleChangePassword = async () => {
    try {
      axios
        .put(
          `${API_BASE_URL}/owner/profile/update-password`,
          {
            current_password: changePassword.c_password,
            password: changePassword.n_password,
            password_confirmation: changePassword.confirm_password,
            email: currentUser.email,
          },
          {
            headers: {
              method: 'PUT',
              Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
            },
          }
        )
        .then((res) => {
          alert('password changed');
          setIsPasswordOpen(false);
        })
        .catch((err) => {
          throw new Error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // const {
  //   zipField: billingZipField,
  //   stateField: billingStateField,
  //   cityField: billingCityField,
  //   addressField: billingAddressField
  // } = useAddressFields({
  //   defaultZip: currentUser?.user_billing_addresses?.[0]?.zip || '',
  //   defaultStateId: currentUser?.user_billing_addresses?.[0]?.state_id || null,
  //   defaultCityId: currentUser?.user_billing_addresses?.[0]?.city_id || null,
  //   defaultAddress: currentUser?.user_billing_addresses?.[0]?.street || '',
  //   labels: {
  //     zip: 'Billing Zip Code',
  //     state: 'Billing State',
  //     city: 'Billing City',
  //     address: 'Billing Address'
  //   },
  // names: {
  //   zip: 'billing_zip',
  //   state: 'billing_state',
  //   city: 'billing_city',
  //   address: 'billing_street'
  // }
  // });

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();

    // Add personal details
    if (title && title !== currentUser.title) {
      data.append('title', title);
    }
    if (firstName && firstName !== currentUser.first_name) {
      data.append('first_name', firstName);
    }
    if (lastName && lastName !== currentUser.last_name) {
      data.append('last_name', lastName);
    }
    if (
      userAddressData.address &&
      userAddressData.address !== currentUser.street
    ) {
      data.append('street', userAddressData.address);
    }
    if (
      userAddressData.city_id &&
      userAddressData.city_id !== currentUser.city_id
    ) {
      data.append('city_id', userAddressData.city_id);
    }
    if (
      userAddressData.state_id &&
      userAddressData.state_id !== currentUser.state_id
    ) {
      data.append('state_id', userAddressData.state_id);
    }
    if (userAddressData.zip && userAddressData.zip !== currentUser.zip) {
      data.append('zip', userAddressData.zip);
    }
    if (timeZoneId) {
      data.append('time_zone_id', timeZoneId);
    }
    // Handle billing address
    if (billingAddressSameAsPersonal) {
      data.append('is_billing_address_same', 1);
      if (userAddressData.address) {
        data.append('billing_street', userAddressData.address);
      }
      if (userAddressData.city_id) {
        data.append('billing_city_id', userAddressData.city_id);
      }
      if (userAddressData.state_id) {
        data.append('billing_state_id', userAddressData.state_id);
      }
      if (userAddressData.zip) {
        data.append('billing_zip', userAddressData.zip);
      }
    } else {
      data.append('is_billing_address_same', 0);
      if (billingAddressData.address) {
        data.append('billing_street', billingAddressData.address);
      }
      if (billingAddressData.city_id) {
        data.append('billing_city_id', billingAddressData.city_id);
      }
      if (billingAddressData.state_id) {
        data.append('billing_state_id', billingAddressData.state_id);
      }
      if (billingAddressData.zip) {
        data.append('billing_zip', billingAddressData.zip);
      }
      // if (billingAddressField.value) {
      //   data.append('billing_street', billingAddressField.value);
      // }
      // if (billingCityField.value?.value) {
      //   data.append('billing_city_id', billingCityField.value.value);
      // }
      // if (billingStateField.value?.value) {
      //   data.append('billing_state_id', billingStateField.value.value);
      // }
      // if (billingZipField.value) {
      //   data.append('billing_zip', billingZipField.value);
      // }
    }

    // Add company details
    if (companyName) {
      data.append('company_name', companyName);
    }

    // if (officeManager && officeManager !== currentUser.companies[0].office_manager) {
    if (officeManager) {
      data.append('office_manager', officeManager);
    }
    if (contactPhone) {
      data.append('contact_phone', contactPhone);
    }
    if (secondEmail) {
      data.append('second_email', secondEmail);
    }
    if (practiceManagementSoftware) {
      data.append('practice_management_software', practiceManagementSoftware);
    }
    if (companyWebsite) {
      data.append('company_website', companyWebsite);
    }
    if (doctorCell) {
      data.append('doctor_cell', doctorCell);
    }
    if (companySpecialtyId) {
      data.append('speciality_id', companySpecialtyId);
    }
    if (fax) {
      data.append('fax', fax);
    }
    if (afterWorkingHoursPhone) {
      data.append('after_work_phone', afterWorkingHoursPhone);
    }

    // Handle file upload
    if (uploadFile) {
      data.append('upload_file', uploadFile);
    }

    axios
      .post(`${API_BASE_URL}/owner/profile`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        dispatch(setUser(res.data.data));
        localStorage.setItem('user', JSON.stringify(res.data.data));
        setProfileSubmit(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateBillingAddress = (field, value) => {
    setBillingAddressData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const updateUserAddress = (field, value) => {
    setUserAddressData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // const handleStateChange = (event, newValue) => {
  //   setStateId(newValue ? newValue.value : '');
  //   setStateValue(newValue);
  //   setCityValue(null);
  //   setCityOptions([]);
  //   if (newValue) {
  //     getCities(newValue.value);
  //   }
  // };

  const handleUserUpdate = (updatedUser) => {
    dispatch(setUser(updatedUser));
    localStorage.setItem('user', JSON.stringify(updatedUser));
  };

  return (
    <Layout
      items={[
        {
          name: 'My Account',
          link: '/',
          icon: <Person2Outlined sx={{ py: 0.2 }} />,
        },
        {
          name: 'Office Profile',
          link: '/owner/account/profile',
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.666 0H3.33268V1.66667H16.666V0ZM16.666 3.33333H3.33268C2.41602 3.33333 1.66602 4.08333 1.66602 5V15C1.66602 15.9167 2.41602 16.6667 3.33268 16.6667H16.666C17.5827 16.6667 18.3327 15.9167 18.3327 15V5C18.3327 4.08333 17.5827 3.33333 16.666 3.33333ZM16.666 15H3.33268V5H16.666V15ZM3.33268 18.3333H16.666V20H3.33268V18.3333ZM14.9993 11.6667H4.99935V13.3333H14.9993V11.6667ZM4.99935 9.16667H14.9993V10.8333H4.99935V9.16667ZM14.9993 6.66667H4.99935V8.33333H14.9993V6.66667Z"
                fill={'#0000008A'}
              />
            </svg>
          ),
        },
      ]}
    >
      <Grid
        sx={{
          px: 3,
          pt: 2,
          pb: 1,
          borderBottom: '1px solid #D9D9D9',
          width: 'auto',
        }}
      >
        <h4 className="pb-0 mb-1" style={{ color: '#262626' }}>
          Office Profile
        </h4>
        <p style={{ color: '#8C8C8C', fontSize: '0.8rem' }}>
          General Settings of Office Profile
        </p>
      </Grid>
      <div
        style={{
          padding: '24px',
          backgroundColor: '#fff',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '270px',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <p style={{ color: '#5c5a5a', fontSize: '0.9rem' }}>
              Email Address
              <sup
                style={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '0.9rem',
                }}
              >
                *
              </sup>
            </p>
            <p
              className="pb-0 mb-1"
              style={{
                color: '#262626',
                fontSize: '15px',
                fontWeight: '500',
              }}
            >
              {currentUser && currentUser.email}
            </p>
            <p
              onClick={() => setIsEmailDialogOpen(true)}
              style={{
                textDecoration: 'underline',
                color: 'blue',
                fontSize: '0.9rem',
                marginTop: '10px',
                cursor: 'pointer',
              }}
            >
              Edit
            </p>
          </div>
          <div>
            <p style={{ color: '#5c5a5a', fontSize: '0.9rem' }}>Password</p>
            <p
              className="pb-0 mb-1"
              style={{
                color: '#262626',
                fontSize: '15px',
                fontWeight: '500',
              }}
            >
              ***********
            </p>
            <p
              onClick={() => setIsPasswordOpen(true)}
              style={{
                textDecoration: 'underline',
                color: 'blue',
                fontSize: '0.9rem',
                marginTop: '10px',
                cursor: 'pointer',
              }}
            >
              Edit
            </p>
          </div>
        </div>

        <Grid
          item
          xs={12}
          style={{
            marginTop: '25px',
          }}
        >
          <Typography variant="h6" component="h3">
            Office Details
          </Typography>
        </Grid>

        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            sx={{
              paddingTop: '0.85rem',
              flexDirection: { xs: 'column-reverse', md: 'row' },
            }}
          >
            <Grid item sm={12} md={8}>
              <Grid
                container
                spacing={2}
                sx={{
                  paddingTop: '0.85rem',
                }}
              >
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      id="title"
                      name="title"
                      label="Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <TextField
                      id="firstName"
                      name="first_name"
                      label="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <TextField
                      id="lastName"
                      name="last_name"
                      label="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  paddingTop: '1.25rem',
                }}
              >
                {/* <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <TextField
                      id="zip"
                      name="zip"
                      label="Zip"
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                      onInput={(e) => {
                        if (e.target.value.length > 5) {
                          e.target.value = e.target.value.slice(0, 5);
                        }
                      }}
                      inputProps={{
                        pattern: '^\\d{5}$',
                        title: 'ZIP code can be only 5-digit',
                        minLength: 5,
                        maxLength: 5,
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" className="w-100">
                    <Autocomplete
                      options={stateOptions}
                      getOptionLabel={(option) => option.label}
                      value={stateValue || null}
                      onChange={handleStateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          variant="outlined"
                          className="w-100 mb-2"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      options={cityOptions}
                      getOptionLabel={(option) => option.label}
                      value={cityValue || null}
                      onChange={(event, newValue) => {
                        setCityId(newValue ? newValue.value : '');
                        setCityValue(newValue);
                      }}
                      onInputChange={handleCityInputChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="City"
                          variant="outlined"
                          className="w-100 mb-2"
                        />
                      )}
                      // MenuProps={{
                      //   PaperProps: {
                      //     onScroll: (e) => {
                      //       const target = e.target;
                      //       if (
                      //         target.scrollHeight - target.scrollTop ===
                      //         target.clientHeight
                      //       ) {
                      //         console.log('Reached the end of the dropdown');
                      //       }
                      //     },
                      //   },
                      // }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      id="address"
                      name="address"
                      label="Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </FormControl>
                </Grid> */}

                <GeneralAddress
                  defaultValues={userAddressData}
                  labels={{
                    zip: 'Zip Code',
                    state: 'State',
                    city: 'City',
                    address: 'Address',
                  }}
                  names={{
                    zip: 'zip',
                    state: 'state_id',
                    city: 'city_id',
                    address: 'address',
                  }}
                  updateAddressData={updateUserAddress}
                />

                <Grid item xs={12} sm={4} />

                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="timeZone">Time Zone</InputLabel>
                    <Select
                      id="timeZone"
                      name="timeZone"
                      label="Time Zone"
                      value={timeZoneId}
                      onChange={(e) => setTimeZoneId(e.target.value)}
                    >
                      {timeZoneOptions.map((timeZone) => (
                        <MenuItem key={timeZone.id} value={timeZone.id}>
                          {timeZone.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  paddingTop: '1.25rem',
                }}
              >
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="billingAddressSameAsPersonal"
                        name="billingAddressSameAsPersonal"
                        checked={billingAddressSameAsPersonal}
                        onChange={(e) =>
                          setBillingAddressSameAsPersonal(e.target.checked)
                        }
                      />
                    }
                    label="Billing address is the same as office address."
                  />
                </Grid>
              </Grid>
              {/* {!billingAddressSameAsPersonal && statesLoaded && ( */}
              {!billingAddressSameAsPersonal && (
                // <>
                //   <Grid item xs={12} sm={4}>
                //     <FormControl fullWidth>
                //       <TextField
                //         {...billingZipField}
                //         InputProps={{
                //           ...billingZipField.inputProps,
                //           endAdornment: (
                //             <InputAdornment position="end">
                //               {billingZipField.loading ? (
                //                 <CircularProgress size="1rem" />
                //               ) : billingZipField.valid ? (
                //                 <CheckIcon />
                //               ) : billingZipField.value.length === 5 ? (
                //                 <ClearIcon />
                //               ) : null}
                //             </InputAdornment>
                //           ),
                //         }}
                //       />
                //     </FormControl>
                //   </Grid>

                //   <Grid item xs={12} sm={4}>
                //     <FormControl variant="outlined" fullWidth>
                //       <Autocomplete
                //         {...billingStateField}
                //         options={allStates}
                //         renderInput={(params) => (
                //           <TextField
                //             {...params}
                //             label={billingStateField.label}
                //             variant="outlined"
                //             className="w-100 mb-2"
                //           />
                //         )}
                //       />
                //     </FormControl>
                //   </Grid>

                //   <Grid item xs={12} sm={4}>
                //     <FormControl variant="outlined" fullWidth>
                //       <Autocomplete
                //         {...billingCityField}
                //         renderInput={(params) => (
                //           <TextField
                //             {...params}
                //             label={billingCityField.label}
                //             variant="outlined"
                //             className="w-100 mb-2"
                //           />
                //         )}
                //         ListboxProps={{
                //           onScroll: (event) => {
                //             const listboxNode = event.currentTarget;
                //             if (
                //               listboxNode.scrollTop +
                //                 listboxNode.clientHeight ===
                //               listboxNode.scrollHeight
                //             ) {
                //               billingCityField.onMenuScrollToBottom();
                //             }
                //           },
                //         }}
                //       />
                //     </FormControl>
                //   </Grid>

                //   <Grid item xs={12} sm={4}>
                //     <FormControl fullWidth>
                //       <TextField {...billingAddressField} />
                //     </FormControl>
                //   </Grid>
                // </>
                <Grid container spacing={2} my={1}>
                  <GeneralAddress
                    defaultValues={billingAddressData}
                    labels={{
                      zip: 'Billing Zip Code',
                      state: 'Billing State',
                      city: 'Billing City',
                      address: 'Billing Address',
                    }}
                    names={{
                      zip: 'billing_zip',
                      state: 'billing_state',
                      city: 'billing_city',
                      address: 'billing_street',
                    }}
                    updateAddressData={updateBillingAddress}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ProfilePicture />
            </Grid>
          </Grid>
          <Grid
            item
            container
            spacing={2}
            sx={{
              paddingTop: '0.85rem',
            }}
            sm={12}
            md={8}
          >
            <Grid item xs={12}>
              <Typography variant="h6" component="h3">
                Company Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <TextField
                  id="companyName"
                  name="companyName"
                  label="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <TextField
                  id="officeManager"
                  name="officeManager"
                  label="Office Manager"
                  value={officeManager}
                  onChange={(e) => setOfficeManager(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                {/* <TextField
                  id="afterWorkingHoursPhone"
                  name="afterWorkingHoursPhone"
                  label="After working hours phone number"
                  value={afterWorkingHoursPhone}
                  onChange={(e) => setAfterWorkingHoursPhone(e.target.value)}
                /> */}
                <PhoneNumberInput
                  label="After working hours phone number"
                  value={afterWorkingHoursPhone}
                  onChange={setAfterWorkingHoursPhone}
                  isRequired
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                {/* <TextField
                  id="contactPhone"
                  name="contactPhone"
                  label="Contact Phone"
                  value={contactPhone}
                  onChange={(e) => setContactPhone(e.target.value)}
                /> */}
                <PhoneNumberInput
                  label="Contact Phone"
                  value={contactPhone}
                  onChange={setContactPhone}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <TextField
                  id="secondEmail"
                  name="secondEmail"
                  label="Second Email"
                  value={secondEmail}
                  onChange={(e) => setSecondEmail(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <TextField
                  id="practiceManagementSoftware"
                  name="practiceManagementSoftware"
                  label="Practice Management Software"
                  value={practiceManagementSoftware}
                  onChange={(e) =>
                    setPracticeManagementSoftware(e.target.value)
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <TextField
                  id="companyWebsite"
                  name="companyWebsite"
                  label="Company Website"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                {/* <TextField
                  id="doctorCell"
                  name="doctorCell"
                  label="Doctor Cell"
                  value={doctorCell}
                  onChange={(e) => setDoctorCell(e.target.value)}
                /> */}
                <PhoneNumberInput
                  label="Doctor Cell"
                  value={doctorCell}
                  onChange={setDoctorCell}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} />
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="company-specialty-label">
                  Company Specialty
                </InputLabel>
                <Select
                  id="company-specialty-label"
                  label="Company Specialty"
                  value={companySpecialtyId || ''}
                  onChange={(e) => setCompanySpecialtyId(e.target.value)}
                >
                  {specialityOptions.map((speciality) => (
                    <MenuItem key={speciality.id} value={speciality.id}>
                      {speciality.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <TextField
                  id="fax"
                  name="fax"
                  label="Fax"
                  value={fax}
                  onChange={(e) => setFax(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box paddingTop={2}>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Box>
        </form>
      </div>

      {isEmailDialogOpen && (
        <ChangeEmailDialog
          open={isEmailDialogOpen}
          handleClose={() => setIsEmailDialogOpen(false)}
          // setUser={setUser}
          // changeEmail={changeEmail}
          // setChangeEmail={setChangeEmail}
          // handleChangeEmail={handleChangeEmail}
          // changeEmailResponse={changeEmailResponse}
          currentUser={currentUser}
          setUser={handleUserUpdate}
        />
      )}
      {isPasswordOpen && (
        <ChangePasswordDialog
          open={isPasswordOpen}
          handleClose={() => setIsPasswordOpen(false)}
          changePassword={changePassword}
          setChangePassword={setChangePassword}
          handleChangePassword={handleChangePassword}
        />
      )}
      {profileSubmit && (
        <SuccessModal
          open={profileSubmit}
          handleClose={() => setProfileSubmit(false)}
          successMessage={'Profile Updated Successfully'}
        />
      )}
    </Layout>
  );
};

export default OwnerProfile;
