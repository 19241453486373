import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../config/constants';
import useAddressFields from '../hooks/useAddress';

const GeneralAddress = ({
  defaultValues,
  labels,
  names,
  updateAddressData,
  gridProps,
}) => {
  const { zip, state_id, city_id, address, latitude, longitude } = defaultValues;
  const {
    zip: zipLabel,
    state: stateLabel,
    city: cityLabel,
    address: addressLabel,
  } = labels;
  const {
    zip: zipName,
    state: stateName,
    city: cityName,
    address: addressName,
  } = names;

  const [statesLoaded, setStatesLoaded] = useState(false);
  const [allStates, setAllStates] = useState([]);

  const { zipField, stateField, cityField, addressField } = useAddressFields({
    defaultZip: zip || '',
    defaultStateId: state_id || null,
    defaultCityId: city_id || null,
    defaultAddress: address || '',
    defaultLatitude: latitude || '',
    defaultLongitude: longitude || '',

    labels: {
      zip: zipLabel || 'Zip Code',
      state: stateLabel || 'State',
      city: cityLabel || 'City',
      address: addressLabel || 'Address',
    },

    names: {
      zip: zipName || 'zip',
      state: stateName || 'state',
      city: cityName || 'city',
      address: addressName || 'street',
    },
    updateAddressData,
  });

  useEffect(() => {
    getStates();
  }, []);

  const getStates = async () => {
    try {
      const stateRes = await axios.get(
        `${API_BASE_URL}/states?msg=generalAddress`
      );
      const statesArray = Object.values(stateRes.data.data).map((state) => ({
        value: state.id,
        label: state.name,
      }));
      setAllStates(statesArray);
      setStatesLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      {/* {!statesLoaded && <div>Loading...</div>} */}

      {statesLoaded && (
        <>
          <Grid item {...(gridProps || { xs: 12, sm: 4 })}>
            <FormControl fullWidth>
              <TextField
                {...zipField}
                required
                InputProps={{
                  ...zipField.inputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      {zipField.loading ? (
                        <CircularProgress size="1rem" />
                      ) : zipField.valid ? (
                        <CheckIcon />
                      ) : zipField.value.length === 5 ? (
                        <ClearIcon />
                      ) : null}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>

          <Grid item {...(gridProps || { xs: 12, sm: 4 })}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                {...stateField}
                required
                options={allStates}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label={stateField.label}
                    variant="outlined"
                    className="w-100 mb-2"
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item {...(gridProps || { xs: 12, sm: 4 })}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                {...cityField}
                required
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={cityField.label}
                    variant="outlined"
                    className="w-100 mb-2"
                  />
                )}
                ListboxProps={{
                  onScroll: (event) => {
                    const listboxNode = event.currentTarget;
                    if (
                      listboxNode.scrollTop + listboxNode.clientHeight ===
                      listboxNode.scrollHeight
                    ) {
                      cityField.onMenuScrollToBottom();
                    }
                  },
                }}
              />
            </FormControl>
          </Grid>

          <Grid item {...(gridProps || { xs: 12, sm: 4 })}>
            <FormControl fullWidth>
              <TextField {...addressField} />
            </FormControl>
          </Grid>
        </>
      )}
    </>
  );
};

export default GeneralAddress;
