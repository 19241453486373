import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { DoneAll, Send } from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import moment from 'moment';
import Pusher from 'pusher-js';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/slices/userSlice';
import { API_BASE_URL } from '../../config/constants';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    height: 'calc(100vh - 110px)',
    borderTop: '1px solid #e0e0e0',
    borderRadius: '0px',
  },
  headBG: {
    backgroundColor: '#e0e0e0',
  },
  borderRight500: {
    borderRight: '1px solid #e0e0e0',
  },
  borderLeft500: {
    borderLeft: '1px solid #e0e0e0',
  },
  messageArea: {
    height: '75vh',
    overflowY: 'auto',
  },
  unreadBadge: {
    backgroundColor: '#FFCF33',
  },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const Chat = ({ chatUsers }) => {
  const classes = useStyles();
  const currentUser = useSelector(selectUser);
  const [selectedChatUser, setSelectedChatUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState();

  const pusher = new Pusher('9327abb9d05e103ead60', {
    cluster: 'mt1',
  });

  // useEffect(() => {
  //   if (selectedChatUser) {
  //     const channel = pusher.subscribe(selectedChatUser.socket_channel);
  //     const event = selectedChatUser.participants[0].conversation.event_name;

  //     channel.bind(event, function (data) {
  //       setMessages((prevMessages) => [...prevMessages, data]);
  //     });
  //     return () => {
  //       channel.unbind(event);
  //       pusher.unsubscribe(selectedChatUser.socket_channel);
  //     };
  //   }
  // }, [selectedChatUser]);

  useEffect(() => {
    if (selectedChatUser) {
      const channel = pusher.subscribe(selectedChatUser.socket_channel);
      const event = selectedChatUser.participants[0].conversation.event_name;
      channel.bind(event, function (data) {
        setMessages((prevMessages) => [...prevMessages, data]);
      });
      return () => {
        channel.unbind(event);
        pusher.unsubscribe(selectedChatUser.socket_channel);
      };
    }
  });

  const fetchMessages = async () => {
    axios
      .get(`${API_BASE_URL}/messages?user_id=${selectedChatUser.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      })
      .then((response) => {
        setMessages([]);
        const reversedMessages = [...response.data.data].reverse();
        var messagesList = reversedMessages.map((message) => {
          return {
            message: message.message,
            time: moment(message.created_at).format('h:mm a'),
            type: message.user_id === currentUser.id ? 'sent' : 'received',
          };
        });
        console.log('WAQAS === > ', messages);
        setMessages(messagesList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const markMessagesAsRead = async () => {
    axios
      .put(
        `${API_BASE_URL}/conversations/${selectedChatUser.participants[0].conversation_id}/view`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        }
      )
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log('Chat === > ', messages);
  }, [messages]);

  useEffect(() => {
    if (selectedChatUser) {
      setMessages([]);
      fetchMessages();
      markMessagesAsRead();
    }
  }, [selectedChatUser]);

  const sendMessage = async ({ userId, message }) => {
    axios
      .post(
        `${API_BASE_URL}/messages`,
        {
          user_id: userId,
          message: message,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        }
      )
      .then((response) => {
        setMessage('');
        fetchMessages();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Grid container component={Paper} className={classes.chatSection}>
        <Grid item xs={3} className={classes.borderRight500}>
          <div>
            {chatUsers.map((user) => (
              <ChatUser
                key={user.id}
                user={user}
                selectedChatUser={selectedChatUser}
                setSelectedChatUser={setSelectedChatUser}
              />
            ))}
          </div>
        </Grid>
        <Grid item xs={9} sm={6}>
          {selectedChatUser ? (
            <Messenger
              sendMessage={sendMessage}
              message={message}
              messages={messages}
              setMessage={setMessage}
              selectedChatUser={selectedChatUser}
            />
          ) : (
            <div
              className={classes.messageArea}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h1
                style={{
                  color: '#BFBFBF',
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                Select a user to start messaging
              </h1>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={3} className={classes.borderLeft500}>
          {selectedChatUser && (
            <CorrespondenceTemplates
              sendMessage={sendMessage}
              selectedChatUser={selectedChatUser}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Chat;

const ChatUser = ({ user, selectedChatUser, setSelectedChatUser }) => {
  return (
    <ListItem
      button
      key={user.id}
      onClick={() => setSelectedChatUser(user)}
      style={{
        backgroundColor: selectedChatUser?.id === user.id ? '#ADCDF6' : 'white',
        gap: '10px',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '10px',
        }}
      >
        <div>
          {user.status === 'online' ? (
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
            >
              <Avatar alt={user.name} src={user.avatar} />
            </StyledBadge>
          ) : (
            <Avatar alt={user.name} src={user.avatar} />
          )}
        </div>
        <div>
          <p
            style={{
              fontSize: '14px',
              fontWeight: 500,
              color: '#262626',
            }}
          >
            {user.name}
          </p>
          <p
            style={{
              fontSize: '14px',
              fontWeight: 400,
              color: '#8C8C8C',
            }}
          >
            {user.lastMessage}
          </p>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          gap: '12px',
        }}
      >
        <p
          style={{
            fontSize: '10px',
            textAlign: 'right',
            color: '#8C8C8C',
          }}
        >
          {user.time}
        </p>
        <>
          {user.unread > 0 ? (
            <Badge
              badgeContent={user.unread}
              color="warning"
              style={{
                marginRight: '10px',
              }}
            ></Badge>
          ) : (
            <DoneAll />
          )}
        </>
      </div>
    </ListItem>
  );
};

const Messenger = ({
  sendMessage,
  message,
  messages,
  setMessage,
  selectedChatUser,
}) => {
  const currentUser = useSelector(selectUser);
  const classes = useStyles();
  const [mes, setMes] = useState([]);

  // const messagesList = messages.map((message) => {
  //   return {
  //     message: message.message,
  //     time: moment(message.created_at).format('h:mm a'),
  //     type: message.user_id === currentUser.id ? 'sent' : 'received',
  //   };
  // });

  useEffect(() => {
    const messageArea = document.querySelector('#messageArea');
    messageArea.scrollTop = messageArea.scrollHeight;
  }, [messages]);

  // useEffect(() => {
  //   console.log('messagesList === > ', messages);
  // }, []);

  return (
    <>
      <List className={classes.messageArea} id="messageArea">
        {messages.length > 0 && <></>}
        <div
          style={{
            padding: '20px',
          }}
        >
          {messages.length > 0 &&
            messages.map((message, index) => {
              console.log('Render === > index', index, message);
              return (
                <div
                  key={message.time}
                  style={{
                    display: 'flex',
                    justifyContent:
                      message.type === 'sent' ? 'flex-end' : 'flex-start',
                  }}
                >
                  <div
                    style={{
                      backgroundColor:
                        message.type === 'sent' ? '#4A93F0' : '#E8E8E8',
                      color: message.type === 'sent' ? 'white' : 'black',
                      padding: '13px 13px 5px 13px',
                      borderRadius: '20px',
                      maxWidth: '70%',
                      marginBottom: '15px',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '12px',
                        fontWeight: 400,
                      }}
                    >
                      {message.message}
                    </p>
                    <p
                      style={{
                        fontSize: '10px',
                        textAlign: 'right',
                        color: message.type === 'sent' ? '#1B5198' : '#BFBFBF',
                      }}
                    >
                      {message.time}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </List>
      <Divider />
      <div
        style={{
          padding: '10px',
          backgroundColor: 'white',
          gap: '10px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div
          style={{
            width: '100%',
          }}
        >
          <input
            type="text"
            style={{
              backgroundColor: '#F5F5F5',
              borderRadius: '20px',
              padding: '10px',
              border: 'none',
              outline: 'none',
              width: '100%',
            }}
            placeholder="Type a message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                sendMessage({ userId: selectedChatUser.id, message });
              }
            }}
          />
        </div>
        <div xs={1} align="right">
          <Fab
            color="primary"
            size="small"
            aria-label="add"
            onClick={() =>
              sendMessage({ userId: selectedChatUser.id, message })
            }
            disabled={message === ''}
          >
            <Send fontSize="small" />
          </Fab>
        </div>
      </div>
    </>
  );
};

const CorrespondenceTemplates = ({ sendMessage, selectedChatUser }) => {
  const templates = [
    'Dr is concerned with you running late. Respond via Mayday Dental app by selecting "Assignments", click "Temporary" and click the red alert bell for responses."',
    'Thank you',
    'Please hurry. Patients are waiting',
    'I am going to mark you as a No Show. Please DO NOT come in.',
    'You left an item in the office. Please contact us.',
    'We are missing some information from you and cannot send out your check yet. Please contact us.',
    'We have a question regarding your past assignment with us. Please contact us.',
  ];
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 110px)',
        overflowY: 'auto',
        padding: 13,
        gap: 10,
      }}
    >
      <div>
        <h3
          style={{
            color: '#BFBFBF',
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          Correspondence Templates
        </h3>
        <p
          style={{
            color: '#BFBFBF',
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          Click on template to send message for the professional
        </p>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '13px',
        }}
      >
        {templates.map((template) => (
          <div
            key={template}
            style={{
              backgroundColor: '#D7E8FF',
              padding: '10px',
              borderRadius: '20px',
              cursor: 'pointer',
            }}
            onClick={() =>
              sendMessage({ userId: selectedChatUser.id, message: template })
            }
          >
            <p
              style={{
                color: '#1B5198',
                fontSize: 12,
                fontWeight: 400,
              }}
            >
              {template}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
