import Close from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './SuccessModal.css';

export default function ErrorModal({ open, handleClose, errorMessage }) {
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (open) {
      const timeoutId = setTimeout(() => {
        setClosing(true);
        setTimeout(() => {
          handleClose();
        }, 300);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [open, handleClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#595959"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#595959"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ padding: '16px' }}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div
              style={{
                borderRadius: '50%',
                border: '1px solid #E8E8E8',
                padding: '16px',
              }}
            >
              <Close fontSize="large" style={{ color: '#4CAF50' }} />
            </div>
            <h2
              id="modal-modal-title"
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                marginTop: '16px',
                color: '#4CAF50',
              }}
            >
              Error
            </h2>
            <p
              style={{
                fontSize: '12px',
                fontWeight: 'normal',
                marginTop: '8px',
                color: '#595959',
                textAlign: 'center',
              }}
            >
              {errorMessage}
            </p>
          </div>
        </div>
        <div className={`loading-bar ${closing ? 'closing' : ''}`}></div>
      </DialogContent>
    </Dialog>
  );
}
