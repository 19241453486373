import AccountCircle from '@mui/icons-material/AccountCircle';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Box, Grid } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CheckboxIcon from '../../../assets/icons/checkboxIcon.svg';
import StarRating from '../../../components/General/StarRating';
import Layout from '../../../components/Layout';
import { API_BASE_URL } from '../../../config/constants';
import useMobile from '../../../hooks/useMobile';
import { selectUser } from '../../../redux/slices/userSlice';
import JobRatingDetailsModal from './JobRatingDetailsModal';

const JobDetails = () => {
  const user = useSelector(selectUser);
  const [jobDetails, setJobDetails] = useState({});
  const [postingApplicants, setPostingApplicants] = useState([]);
  const [postingSchedules, setPostingSchedules] = useState([]);
  const [ratingDetailsOpen, setRatingDetailsOpen] = useState(false);
  const jobId = window.location.href.split('/').pop();
  const authToken = localStorage.getItem('auth_token');
  const navigate = useNavigate();
  const [jobStartDateTime, setJobStartDateTime] = useState(null);
  const [jobEndDateTime, setJobEndDateTime] = useState(null);

  const lo_user = localStorage.getItem('user');
  const lo_user_ob = JSON.parse(lo_user);

  const fetchJobDetails = () => {
    axios
      .get(`${API_BASE_URL}/postings/${jobId}`, {
        headers: {
          method: 'GET',
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        setJobDetails(res.data.data);
        setPostingApplicants(res.data.data.posting_applicants);
        setPostingSchedules(res.data.data.posting_schedules);
        const start = res.data.data.posting_schedules.shift(0);
        const end = res.data.data.posting_schedules.pop();
        if (end == undefined) {
          end = start;
        }
        setJobStartDateTime(start);
        setJobEndDateTime(end);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    // console.log('jobStartDateTime === > ', postingSchedules, jobStartDateTime);
  });

  useEffect(() => {
    fetchJobDetails();
  }, []);

  const isMobile = useMobile(767);
  return (
    <Layout
      items={[
        {
          name: 'Jobs',
          link: '/',
        },
        {
          name: `${
            jobDetails.posting_type === 'temporary'
              ? 'Temporary Jobs'
              : 'Permanent Jobs'
          }`,
          link:
            jobDetails.posting_type === 'temporary'
              ? '/professional/jobs/temporary'
              : '/professional/jobs/permanent',
        },
      ]}
    >
      <div
        className="d-flex"
        style={{
          alignItems: 'center',
          borderBottom: '1px solid #D9D9D9',
        }}
      >
        <Grid
          sx={{
            px: 3,
            pt: 2,
            pb: 1,
          }}
        >
          <h4 className="pb-0 mb-1" style={{ color: '#262626' }}>
            {/* {(postingApplicants[0] ? jobDetails?.user?.companies[0]?.name : '')} */}
            {jobDetails?.user?.companies[0]?.name}
          </h4>
          <p style={{ color: '#8C8C8C', fontSize: '0.8rem' }}>
            {jobDetails?.user_location?.place_name}
          </p>
        </Grid>
      </div>
      <Box margin={{ xs: '10px', sm: '30px' }}>
        <Grid container>
          <Grid xs={12} md={8} lg={7.5} xl={6}>
            <div
              style={{
                borderRadius: '6px',
                border: '1px solid #D9D9D9',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <div
                style={{
                  backgroundColor: '#FAFAFA',
                  borderBottom: '1px solid #D9D9D9',
                  padding: '25px',
                  borderRadius: '6px 6px 0px 0px',
                }}
              >
                <div
                  className="d-flex"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className="d-flex">
                    {jobDetails?.user?.avatar ? (
                      <img
                        style={{
                          width: '60px',
                          height: '60px',
                          borderRadius: '50%',
                          objectFit: 'cover',
                          marginRight: '20px',
                        }}
                        src={`${API_BASE_URL}/assets/${jobDetails?.user?.avatar}`}
                        alt=""
                      />
                    ) : (
                      <AccountCircle sx={{ fontSize: '60px' }} />
                    )}
                    <div>
                      <p
                        style={{
                          color: '#262626',
                          fontSize: '20px',
                          fontWeight: 500,
                          margin: '0px',
                        }}
                      >
                        {jobDetails?.user?.first_name}{' '}
                        {jobDetails?.user?.last_name}
                      </p>
                      <p
                        style={{
                          color: '#8C8C8C',
                          fontSize: '16px',
                          fontWeight: 400,
                          margin: '0px',
                          textTransform: 'uppercase',
                        }}
                      >
                        {/* Practice Owner of  {(postingApplicants[0] ? jobDetails?.user?.companies[0]?.name : '*****')} */}
                        Practice Owner of {jobDetails?.user?.companies[0]?.name}
                      </p>
                    </div>
                  </div>
                  <div>
                    {postingApplicants[0] ? (
                      <Button
                        variant="outline-primary"
                        onClick={() => navigate('/messages')}
                      >
                        Open Chat
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <Grid
                  container
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  py={2}
                  spacing={1}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    sm={6}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    py={{ xs: 1, sm: 0 }}
                    spacing={1}
                  >
                    <Grid item xs={6} sm={4}>
                      <p
                        style={{
                          color: '#262626',
                          fontSize: '14px',
                          fontWeight: 500,
                          margin: '0px',
                        }}
                      >
                        Average Ratting
                      </p>
                    </Grid>
                    <Grid item xs={3} sm={4} sx={{ px: { xs: 2, sm: 1 } }}>
                      {jobDetails?.user?.average_score && (
                        <StarRating rating={jobDetails?.user?.average_score} />
                      )}
                    </Grid>
                    <Grid item xs={3} sm={4}>
                      <p
                        style={{
                          color: '#2561B0',
                          fontSize: '14px',
                          fontWeight: 400,
                          margin: '0px',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        onClick={() => setRatingDetailsOpen(true)}
                      >
                        Rate
                      </p>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <p
                      style={{
                        color: '#8C8C8C',
                        fontSize: '12px',
                        fontWeight: 400,
                        margin: '0px',
                      }}
                    >
                      Last Review: &nbsp;
                      <span
                        style={{
                          color: '#262626',
                          fontSize: '12px',
                          fontWeight: 400,
                          margin: '0px',
                        }}
                      >
                        {moment(jobDetails?.user?.last_review_at).format(
                          'MM/DD/YYYY [l] h:mm A'
                        )}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <p
                      style={{
                        color: '#2561B0',
                        fontSize: '14px',
                        fontWeight: 400,
                        margin: '0px',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                    >
                      Job History
                    </p>
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  padding: '25px',
                  backgroundColor: '#FFFFFF',
                  borderRadius: '0px 0px 6px 6px',
                }}
              >
                <h3
                  style={{
                    color: '#262626',
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                >
                  Job Details
                </h3>

                <Grid
                  container
                  marginTop={'20px'}
                  justifyContent={'space-between'}
                  spacing={2}
                >
                  <Grid item xs={8}>
                    <p
                      style={{
                        color: '#595959',
                        fontSize: '12px',
                        fontWeight: 400,
                        margin: '0px',
                      }}
                    >
                      Category
                    </p>
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: 400,
                        margin: '0px',
                      }}
                    >
                      {jobDetails?.category?.name}
                    </p>
                  </Grid>
                  <Grid item xs={4}>
                    <p
                      style={{
                        color: '#595959',
                        fontSize: '12px',
                        fontWeight: 400,
                        margin: '0px',
                      }}
                    >
                      Location
                    </p>
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: 400,
                        margin: '0px',
                      }}
                    >
                      {jobDetails?.user_location?.place_name}
                    </p>
                  </Grid>
                  <Grid item xs={8}>
                    <p
                      style={{
                        color: '#595959',
                        fontSize: '12px',
                        fontWeight: 400,
                        margin: '0px',
                      }}
                    >
                      Posting Title
                    </p>
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: 400,
                        margin: '0px',
                      }}
                    >
                      {jobDetails.title}
                    </p>
                  </Grid>

                  <Grid item xs={4}>
                    <p
                      style={{
                        color: '#595959',
                        fontSize: '12px',
                        fontWeight: 400,
                        margin: '0px',
                      }}
                    >
                      Distance
                    </p>
                    <p>
                      <span
                        style={{
                          color: '#2561B0',
                          textDecoration: 'underline',
                          fontSize: '14px',
                        }}
                      >
                        {jobDetails?.distance}
                      </span>
                      <LocationOnOutlinedIcon
                        style={{ marginLeft: 5, color: '#FA5A16' }}
                      />
                    </p>
                  </Grid>
                </Grid>

                <hr />
                <h3
                  style={{
                    color: '#262626',
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                >
                  Duration
                </h3>
                <div
                  className="d-flex"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                  }}
                >
                  <div>
                    <p
                      style={{
                        color: '#595959',
                        fontSize: '12px',
                        fontWeight: 400,
                        margin: '0px',
                      }}
                    >
                      Start Day
                    </p>
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: 400,
                        margin: '0px',
                      }}
                    >
                      {jobStartDateTime
                        ? moment
                            .utc(
                              jobStartDateTime.start_time,
                              'YYYYMMDD HH:mm:ss'
                            )
                            .tz(lo_user_ob.user_time_zone)
                            .format('MM/DD/YY hh:mm A')
                        : '----------'}
                    </p>
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: 400,
                        margin: '0px',
                      }}
                    ></p>
                  </div>
                  <div>
                    <p
                      style={{
                        color: '#595959',
                        fontSize: '12px',
                        fontWeight: 400,
                        margin: '0px',
                      }}
                    >
                      End Day
                    </p>
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: 400,
                        margin: '0px',
                      }}
                    >
                      {jobEndDateTime
                        ? moment
                            .utc(jobEndDateTime.end_time, 'YYYYMMDD HH:mm:ss')
                            .tz(lo_user_ob.user_time_zone)
                            .format('MM/DD/YY hh:mm A')
                        : '----------'}
                    </p>
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: 400,
                        margin: '0px',
                      }}
                    ></p>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
      {jobDetails.posting_type === 'temporary' && (
        <Box margin={{ xs: '10px', sm: '30px' }}>
          <Grid container>
            <Grid item container xs={12} md={8} lg={7.5} xl={6} spacing={2}>
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '6px',
                    border: '1px solid #D9D9D9',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    padding: '25px',
                  }}
                >
                  <h3
                    style={{
                      color: '#262626',
                      fontSize: '16px',
                      fontWeight: 500,
                    }}
                  >
                    Job Management
                  </h3>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      marginTop: '20px',
                    }}
                  >
                    <div>
                      <p
                        style={{
                          color: '#595959',
                          fontSize: '12px',
                          fontWeight: 400,
                          margin: '0px',
                        }}
                      >
                        Days Approved
                      </p>
                      <div
                        className="d-flex"
                        style={{
                          gap: 5,
                          alignItems: 'center',
                        }}
                      >
                        <img src={CheckboxIcon} alt="" />
                        <span>{jobDetails.application_approved_count}</span>
                        <ArrowDropUpIcon sx={{ color: '#262626' }} />
                      </div>
                    </div>
                    <div>
                      <p
                        style={{
                          color: '#595959',
                          fontSize: '12px',
                          fontWeight: 400,
                          margin: '0px',
                        }}
                      >
                        Available Day(s)
                      </p>
                      <p
                        style={{
                          color: '#2561B0',
                          textDecoration: 'underline',
                          fontSize: '14px',
                        }}
                      >
                        {jobDetails.schedules_count}
                      </p>
                    </div>
                  </div>
                  <div>
                    <Button
                      style={{
                        width: '100%',
                        marginTop: '20px',
                        backgroundColor: '#4CAF50',
                        border: 0,
                      }}
                      variant="success"
                      onClick={() =>
                        navigate(
                          `/professional/jobs/temporary/${jobId}/calendar`
                        )
                      }
                    >
                      Access Calendar View
                    </Button>

                    {/* {postingApplicants[0] ? (
                <>
                  <Button
                    style={{
                      width: '100%',
                      marginTop: '20px',
                      backgroundColor: '#4CAF50',
                      border: 0,
                    }}
                    variant='success'
                    onClick={() =>
                      navigate(`/professional/jobs/temporary/${jobId}/calendar`)
                    }
                  >
                    Access Calendar View
                  </Button>
                </>
              ) : (
                <></>
              )} */}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    backgroundColor: '#FFF8E1',
                    borderRadius: '6px',
                    padding: '8px 16px',
                    height: 'fit-content',
                  }}
                >
                  <div className="d-flex">
                    <ErrorOutlineOutlinedIcon
                      sx={{ color: '#FFC400', marginRight: 1 }}
                    />
                    <p
                      style={{ color: '#B28900', fontSize: '14px' }}
                      className="fw-semibold mb-0"
                    >
                      Important:
                    </p>
                  </div>
                  <p
                    style={{
                      color: '#B28900',
                      fontSize: '14px',
                      marginTop: '5px',
                    }}
                  >
                    Please go to Calendar View and choose working day(s) based
                    on your availability. Please be sure to scroll up/down to
                    see all required day(s).
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}

      {ratingDetailsOpen && (
        <JobRatingDetailsModal
          handleClose={() => setRatingDetailsOpen(false)}
          open={ratingDetailsOpen}
          selectedItem={jobDetails}
          key={jobDetails.id}
          fetchData={fetchJobDetails}
        />
      )}
    </Layout>
  );
};

export default JobDetails;
