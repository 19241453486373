import {
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  withStyles,
} from '@material-ui/core';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/icons/Logo.svg';
import shoulder from '../../assets/images/registerPracticeOwner.jpg';
import CompanyDetails from '../../components/PracticeOwnerReg/CompanyDetails';
import PersonalDetails from '../../components/PracticeOwnerReg/PersonalDetails';
import Subscriptions from '../../components/PracticeOwnerReg/Subscriptions';
import { selectUser } from '../../redux/slices/userSlice';

const ColorStepConnector = withStyles({
  active: {
    '& $line': {
      backgroundImage: 'linear-gradient( 95deg,#00c853 0%, #00c853 100%)',
      height: 1.5,
    },
  },
  completed: {
    '& $line': {
      backgroundImage: 'linear-gradient( 95deg, #00c853 0%, #00c853 100%)',
      height: 1.5,
    },
  },
  line: {
    height: 1.5, // Adjusted thickness
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    marginLeft: 12, // Adjusted margin
    marginRight: 12, // Adjusted margin
  },
})(StepConnector);

export default function PracticeOwnerReg() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [activeStep, setActiveStep] = useState(
    user && user.steps_completed ? parseInt(user.steps_completed) + 1 : 0
  );

  useEffect(() => {
    if (!user.role_type) {
      navigate('/login');
    } else if (user && user.role_type === 'general') {
      navigate('/selectRole');
    } else if (user && user.role_type === 'professional') {
      navigate('/registration/professional');
    }

    if (user.steps_completed === 0) {
      setActiveStep(1);
    } else if (user.steps_completed === 1) {
      setActiveStep(2);
    } else if (user.steps_completed === 2) {
      setActiveStep(3);
    } else if (user.steps_completed === 3) {
      if (user.is_contract_signed === true) {
        navigate('/registration/completed');
      } else {
        navigate('/registration/agreement');
      }
    }
  }, [navigate, user]);

  const steps = [
    { label: 'Step 1', alternativeLabel: 'Personal Details' },
    { label: 'Step 2', alternativeLabel: 'Company Details' },
    { label: 'Step 3', alternativeLabel: 'Subscription' },
  ];

  return (
    <>
      <Row className="justify-content-between m-0">
        <Col className="">
          <Box className="mx-2 mx-md-5 my-2 my-md-4">
            <Image src={logo} />
            <div className="text-grey fs-2 my-4 fw-semibold">
              Create an account for Practice Owner
            </div>
            <hr className="text-lightgrey" />
            <Row className="p-0 m-0 mb-2">
              <Stepper
                activeStep={activeStep - 1}
                connector={<ColorStepConnector />}
                className="m-0 p-0"
              >
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepLabel className="text-start">
                      <span className="fw-semibold"> </span>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Row>
            <Row className="d-none d-md-flex p-0 pe-3 m-0 mx-2 justify-content-between ">
              <Col className="px-0 mx-0" sm={3}>
                <span className="fw-semibold">
                  Login Info / Personal Details
                </span>
                <br />
                <span style={{ fontSize: '0.8rem' }} className="text-lightgrey">
                  Step 1
                </span>
              </Col>
              <Col className="px-0 mx-0 text-center" sm={3}>
                <span className="fw-semibold">Address / Company Details</span>
                <br />
                <span style={{ fontSize: '0.8rem' }} className="text-lightgrey">
                  Step 2
                </span>
              </Col>
              <Col className="px-0 mx-0 text-end" sm={3}>
                <span className="fw-semibold mb-0">Subscription</span>
                <br />
                <span style={{ fontSize: '0.8rem' }} className="text-lightgrey">
                  Step 3
                </span>
              </Col>
            </Row>
            <br />
            {activeStep === 1 ? (
              <PersonalDetails setActiveStep={setActiveStep} />
            ) : activeStep === 2 ? (
              <CompanyDetails setActiveStep={setActiveStep} />
            ) : activeStep === 3 ? (
              <Subscriptions setActiveStep={setActiveStep} />
            ) : null}
          </Box>
        </Col>
        <Col
          md={3}
          className="d-none d-md-flex justify-content-end p-0 m-0 overflow-hidden"
          style={{
            backgroundImage: `url(${shoulder})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: '100vh',
          }}
        ></Col>
      </Row>
    </>
  );
}
