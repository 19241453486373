import ErrorIcon from '@mui/icons-material/Error';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import changeEmailImg from '../../../assets/images/change-email.png';
import { API_BASE_URL } from '../../../config/constants';
import { useState } from 'react';

const validationSchema = Yup.object({
  password: Yup.string().required('Current Password is required'),
  email: Yup.string()
    .email('Invalid email format')
    .required('New Email Address is required'),
});

const ChangeEmailDialog = ({ open, handleClose, currentUser, setUser }) => {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [oldOtp, setOldOtp] = useState('');
  const [newOtp, setNewOtp] = useState('');
  const [isOtpStep, setIsOtpStep] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState('');

  const formik = useFormik({
    initialValues: {
      password: '',
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmittedEmail(values.email);
      try {
        const res = await axios.put(
          `${API_BASE_URL}/owner/profile/initiate-email-change`,
          {
            current_password: values.password,
            email: values.email,
          },
          {
            headers: {
              method: 'PUT',
              Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
            },
          }
        );

        setSuccessMessage('OTPs sent to new and old email addresses!');
        formik.setFieldError('email', '');
        formik.setFieldError('password', '');
        formik.resetForm();
        setIsOtpStep(true);
      } catch (error) {
        let errorMessage = 'An unknown error occurred.';

        if (error.response && error.response.data.message) {
          const { fields, description } = error.response.data.message;

          if (fields && fields.length > 0) {
            // If fields are present, join them into a single message
            errorMessage = fields.join(', ');
          } else if (description) {
            // If fields are not present, use the description
            errorMessage = description;
          }

          setErrorMessage(errorMessage);
        }

        setSuccessMessage('');
      }
    },
  });

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.put(
        `${API_BASE_URL}/owner/profile/verify-email-change`,
        {
          otp_old_email: oldOtp,
          otp_new_email: newOtp,
          email: submittedEmail,
        },
        {
          headers: {
            method: 'PUT',
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        }
      );

      setSuccessMessage('Email change verified successfully!');
      setErrorMessage('');
      setIsOtpStep(false);
      setOldOtp('');
      setNewOtp('');

      const data = {
        ...currentUser,
        email: submittedEmail,
      };
      setUser(data);
      handleClose();
    } catch (error) {
      let errorMessage = 'An unknown error occurred.';

      if (error.response && error.response.data.message) {
        const { fields, description } = error.response.data.message;

        if (fields && fields.length > 0) {
          // If fields are present, join them into a single message
          errorMessage = fields.join(', ');
        } else if (description) {
          // If fields are not present, use the description
          errorMessage = description;
        }

        setErrorMessage(errorMessage);
      }

      setSuccessMessage('');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ overflow: 'auto' }}
    >
      <Box
        className="d-flex align-items-center justify-content-center overflow-hidden"
        sx={{
          height: '120px',
          backgroundColor: 'rgb(37, 97, 176)',
        }}
      >
        <Box
          component="img"
          src={changeEmailImg}
          alt="Change Email"
          sx={{
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </Box>
      <DialogTitle id="alert-dialog-title" style={{ fontWeight: 'bold' }}>
        Change E-mail
      </DialogTitle>
      <DialogContent>
        {successMessage && (
          <Alert className="mt-2 mb-3" severity="success">
            {successMessage}
          </Alert>
        )}
        {errorMessage && (
          <Alert className="mt-2 mb-3" severity="error" icon={<ErrorIcon />}>
            {errorMessage}
          </Alert>
        )}

        {!isOtpStep ? (
          <form onSubmit={formik.handleSubmit}>
            <TextField
              className="mt-3"
              label="Current Password"
              type="password"
              autoComplete="current-password"
              required={true}
              {...formik.getFieldProps('password')}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              fullWidth
            />
            <TextField
              className="my-3"
              label="New Email Address"
              type="email"
              autoComplete="email"
              required={true}
              {...formik.getFieldProps('email')}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              fullWidth
            />
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" autoFocus>
                CONFIRM
              </Button>
            </DialogActions>
          </form>
        ) : (
          <form onSubmit={handleOtpSubmit}>
            <TextField
              className="mt-3"
              label="Enter Old Email OTP"
              type="text"
              required={true}
              value={oldOtp}
              onChange={(e) => setOldOtp(e.target.value)}
              fullWidth
            />
            <TextField
              className="my-3"
              label="Enter New Email OTP"
              type="text"
              required={true}
              value={newOtp}
              onChange={(e) => setNewOtp(e.target.value)}
              fullWidth
            />
            <DialogActions>
              <Button onClick={() => setIsOtpStep(false)}>Back</Button>
              <Button type="submit" autoFocus>
                VERIFY
              </Button>
            </DialogActions>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ChangeEmailDialog;
