import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config/constants';

const useAddressFields = ({
  defaultZip = '',
  defaultStateId = null,
  defaultCityId = null,
  defaultAddress = '',
  defaultLatitude = '',
  defaultLongitude = '',
  labels = {
    zip: 'ZIP',
    state: 'State',
    city: 'City',
    address: 'Address',
  },
  names = {
    zip: 'zip',
    state: 'state',
    city: 'city',
    address: 'address',
  },
  updateAddressData,
}) => {
  // State management
  const [zip, setZip] = useState(defaultZip);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [address, setAddress] = useState(defaultAddress);
  const [latitude, setLatitude] = useState(defaultLatitude);
  const [longitude, setLongitude] = useState(defaultLongitude);

  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [cityPage, setCityPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [validZip, setValidZip] = useState(false);

  // Load states and set default state if provided
  useEffect(() => {
    const loadStates = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/states`);
        const states = response.data.data.map((state) => ({
          value: state.id,
          label: state.name,
        }));
        setStateOptions(states);

        // If default state ID is provided, select it
        if (defaultStateId) {
          const stateOption = states.find(
            (state) => state.value === parseInt(defaultStateId)
          );
          if (stateOption) {
            setSelectedState(stateOption);
          }
        }
      } catch (error) {
        console.error('Error loading states:', error);
      }
    };
    loadStates();
  }, [defaultStateId]);

  // Load default city if provided
  useEffect(() => {
    const loadDefaultCity = async () => {
      if (defaultCityId && selectedState) {
        try {
          const response = await axios.get(`${API_BASE_URL}/cities`, {
            params: {
              id: defaultCityId,
              state_id: selectedState.value,
            },
          });
          const city = response.data.data[0];
          if (city) {
            const cityOption = {
              value: city.id,
              label: city.name,
            };
            setCityOptions([cityOption]);
            setSelectedCity(cityOption);
          }
        } catch (error) {
          console.error('Error loading default city:', error);
        }
      }
    };
    loadDefaultCity();
  }, [defaultCityId, selectedState]);

  // Update zip when defaultZip changes
  useEffect(() => {
    setZip(defaultZip);
    updateAddressData('zip', defaultZip);
  }, [defaultZip]);

  // Update address when defaultAddress changes
  useEffect(() => {
    setAddress(defaultAddress);
    updateAddressData('address', defaultAddress);
  }, [defaultAddress]);

  // Rest of the handlers remain the same
  const handleZipChange = async (value) => {
    setZip(value);
    updateAddressData('zip', value);
    if (value && /^\d{5}$/.test(value)) {
      setLoading(true);
      try {
        const response = await axios.get(
          `${API_BASE_URL}/get-city-state/${value}`
        );
        const { state_city, formatted_address, lat_lon } =
          response.data.data.data;

        if (state_city) {
          const stateOption = stateOptions.find(
            (state) => state.value === state_city.id
          );
          if (stateOption) {
            setSelectedState(stateOption);
            updateAddressData('state_id', stateOption.value);
          }

          const cityOption = {
            value: state_city.cities[0].id,
            label: state_city.cities[0].name,
          };
          setCityOptions([cityOption]);
          setSelectedCity(cityOption);
          setAddress(formatted_address);
          setLatitude(lat_lon.latitude);
          setLongitude(lat_lon.longitude);
          setValidZip(true);
          updateAddressData('city_id', cityOption.value);
          updateAddressData('address', formatted_address);
          updateAddressData('latitude', lat_lon.latitude);
          updateAddressData('longitude', lat_lon.longitude);
        }
      } catch (error) {
        console.error('Error fetching ZIP data:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleStateChange = async (event, stateOption) => {
    setSelectedState(stateOption);
    updateAddressData('state_id', stateOption ? stateOption.value : null);
    setSelectedCity(null);
    setCityPage(1);

    // Clear the zip when the state changes
    setZip('');
    updateAddressData('zip', '');

    if (stateOption) {
      await loadCities(stateOption.value);
    } else {
      setCityOptions([]);
    }
  };

  const loadCities = async (stateId, page = 1, searchQuery = '') => {
    if (!stateId) return;

    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/cities`, {
        params: {
          state_id: stateId,
          page,
          name: searchQuery,
        },
      });
      const newCities = response.data.data.map((city) => ({
        value: city.id,
        label: city.name,
      }));

      if (page === 1) {
        setCityOptions(newCities);
      } else {
        setCityOptions((prev) => [...prev, ...newCities]);
      }
    } catch (error) {
      console.error('Error loading cities:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCitySearch = async (searchQuery) => {
    if (selectedState) {
      setCityPage(1);
      await loadCities(selectedState.value, 1, searchQuery);
    }
  };

  const handleCityScroll = () => {
    if (!loading && selectedState) {
      setCityPage((prev) => prev + 1);
      loadCities(selectedState.value, cityPage + 1);
    }
  };

  const handleCityChange = async (event, newValue) => {
    setSelectedCity(newValue);
    updateAddressData('city_id', newValue ? newValue.value : null);

    if (newValue && selectedState) {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/zip-by-city-state/${newValue.label}/${selectedState.label}`
        );
        if (response.data && response.data.data) {
          const { data } = response.data.data;

          const status = data.status;
          if (status === 'OK') {
            setZip(data?.zip_code || '');
            setAddress(data?.formatted_address || '');
            updateAddressData('zip', data?.zip_code || '');
            updateAddressData('address', data?.formatted_address || '');
          } else {
            console.warn(
              'No exact zip code available:',
              response.data.data.message
            );
            setZip('');
            setAddress('');
            updateAddressData('zip', '');
            updateAddressData('address', '');
          }
        }
      } catch (error) {
        setZip('');
        setAddress('');
        updateAddressData('zip', '');
        updateAddressData('address', '');
        console.error('Error fetching ZIP code by city and state:', error);
      }
    }
  };

  const addressFieldChange = (e) => {
    setAddress(e.target.value);
    updateAddressData('address', e.target.value);
  };

  return {
    zipField: {
      value: zip,
      onChange: (e) => handleZipChange(e.target.value),
      label: labels.zip,
      name: names.zip,
      loading,
      valid: validZip,
      inputProps: {
        maxLength: 5,
        pattern: '^\\d{5}$',
      },
    },
    stateField: {
      value: selectedState,
      onChange: handleStateChange,
      options: stateOptions,
      label: labels.state,
      name: names.state,
      loading,
      isOptionEqualToValue: (option, value) => option?.value === value?.value,
    },
    cityField: {
      value: selectedCity,
      onChange: handleCityChange,
      options: cityOptions,
      label: labels.city,
      name: names.city,
      loading,
      onInputChange: (_, newInputValue) => handleCitySearch(newInputValue),
      onMenuScrollToBottom: handleCityScroll,
      isOptionEqualToValue: (option, value) => option?.value === value?.value,
    },
    addressField: {
      value: address,
      onChange: addressFieldChange,
      label: labels.address,
      name: names.address,
    },
  };
};

export default useAddressFields;
