import {
  CloseOutlined,
  ErrorOutlineOutlined,
  MyLocation,
  Person2Outlined,
} from '@mui/icons-material';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneNumberInput from '../../../components/General/PhoneNumberInput';
import Layout from '../../../components/Layout';
import { API_BASE_URL } from '../../../config/constants';
import GeneralAddress from '../../GeneralAddress';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const AddOwnerLocation = () => {
  const navigate = useNavigate();

  // const [latitude, setLatitude] = useState(null);
  // const [longitude, setLongitude] = useState(null);
  // const [stateValue, setStateValue] = useState(null);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  // const [cityValue, setCityValue] = useState(null);
  // const [loader, setLoader] = useState(false);
  const [locationName, setLocationName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [locationEmail, setLocationEmail] = useState('');
  const [locationPhone, setLocationPhone] = useState('');
  // const [address, setAddress] = useState('');
  // const [zip, setZip] = useState('');
  const [timeZoneId, setTimeZoneId] = useState('');
  // const [cityId, setCityId] = useState('');
  // const [stateId, setStateId] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  // const [openState, setOpenState] = useState(false);
  // const [openCity, setOpenCity] = useState(false);
  // const [validZip, setValidZip] = useState(false);
  const divRef = useRef();

  // Using the custom hook for address lookup
  // const { stateOptions, cityOptions, address: fetchedAddress, loader: addressLoader, validZip: addressValidZip, status, lat_lon } = useAddressLookup(zip);

  // useEffect(() => {
  //   if (addressValidZip) {
  //     // Assuming the first state and city are selected when valid ZIP is found
  //     if (stateOptions.length > 0) {
  //       setStateValue(stateOptions[0]);
  //       setStateId(stateOptions[0].value);
  //     }
  //     if (cityOptions.length > 0) {
  //       setCityValue(cityOptions[0]);
  //       setCityId(cityOptions[0].value);
  //     }
  //     setAddress(fetchedAddress);
  //     setLatitude(lat_lon?.latitude);
  //     setLongitude(lat_lon?.longitude);
  //   }
  // }, [addressValidZip, stateOptions, cityOptions, fetchedAddress, lat_lon]);

  const handleClick = () => {
    setAnchorEl(divRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // const handleZipCodeChange = async (e) => {
  //   const newZip = e.target.value;
  //   setZip(newZip);
  // };
  const getTimeZones = async () => {
    try {
      const timeZoneRes = await axios.get(`${API_BASE_URL}/zones`);

      const timeZoneArray = Object.values(timeZoneRes.data.data).map(
        (timeZone) => ({
          id: timeZone.id,
          label: timeZone.zone_name,
        })
      );
      setTimeZoneOptions(timeZoneArray);
    } catch (error) {}
  };

  const [officeAddressData, setOfficeAddressData] = useState({
    zip: '',
    state_id: null,
    city_id: null,
    address: '',
    latitude: '',
    longitude: '',
  });

  const updateOfficeAddressData = (field, value) => {
    console.log(field, value);
    setOfficeAddressData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async (values) => {
    try {
      const data = {
        place_name: values.place_name,
        contact_first_name: values.first_name,
        contact_last_name: values.last_name,
        location_email: values.location_email,
        location_phone: values.location_phone,
        address: officeAddressData.address,
        zip: officeAddressData.zip,
        time_zone_id: values.time_zone_id,
        city_id: officeAddressData.city_id,
        state_id: officeAddressData.state_id,
        latitude: officeAddressData.latitude,
        longitude: officeAddressData.longitude,
      };

      await axios.post(`${API_BASE_URL}/owner/locations`, data, {
        headers: {
          method: 'POST',
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });
      navigate(`/owner/account/locations`);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  useEffect(() => {
    getTimeZones();
  }, []);

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    place_name: Yup.string().required('Practice Name is required'),
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    location_email: Yup.string()
      .email('Invalid email')
      .required('Email is required'),
    location_phone: Yup.string().required('Phone number is required'),
    time_zone_id: Yup.string().required('Time Zone is required'),
    // Add validation for address fields as needed
  });

  return (
    <Layout
      items={[
        {
          name: 'My Account',
          link: '/',
          icon: <Person2Outlined sx={{ py: 0.2 }} />,
        },
        {
          name: 'Locations',
          link: '/owner/account/profile',
          icon: <MyLocation sx={{ py: 0.2 }} />,
        },
      ]}
    >
      <Formik
        initialValues={{
          place_name: locationName,
          first_name: firstName,
          last_name: lastName,
          location_email: locationEmail,
          location_phone: locationPhone,
          time_zone_id: timeZoneId,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          // Handle form submission
          console.log('submit the form', values);
          handleSubmit(values);
        }}
        // onSubmit={handleSubmit}
      >
        {({ handleChange, handleBlur, values }) => (
          <Form>
            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                borderBottom: '1px solid #D9D9D9',
                padding: '20px',
              }}
            >
              <Grid>
                <h4 className="pb-0 mb-1" style={{ color: '#262626' }}>
                  Add Location
                </h4>
                <p style={{ color: '#8C8C8C', fontSize: '0.8rem' }}>
                  Management of Locations
                </p>
              </Grid>
              <div
                className="d-flex justify-content-center align-items-center pointer"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/owner/account/locations`)}
              >
                <p style={{ marginRight: '10px', color: '#808080' }}>Close</p>
                <CloseOutlined
                  sx={{
                    color: '#000',
                    fontSize: '42px',
                    borderRadius: '50%',
                    border: '1px solid #ccc',
                    padding: '8px',
                    backgroundColor: '#fff',
                  }}
                />
              </div>
            </div>

            <Grid
              container
              sx={{
                padding: '20px',
              }}
            >
              <Grid
                item
                lg={8}
                xl={5}
                sx={{
                  backgroundColor: '#D7E8FF',
                  padding: 2,
                  borderRadius: '8px',
                  display: 'flex',
                  gap: 2,
                }}
              >
                <Grid item>
                  <ErrorOutlineOutlined
                    sx={{ color: '#4A93F0', transform: 'rotate(180deg)' }}
                  />
                </Grid>
                <Grid item sx={{ fontSize: '12px' }}>
                  <p
                    style={{
                      color: '#194378',
                      fontWeight: '600',
                      marginBottom: 0,
                    }}
                  >
                    Note
                  </p>
                  <p style={{ color: '#194378' }}>
                    If you want to set up a specific payment type (ie: bank
                    account or Credit Card) for payments on the new added
                    location, you will need to create a new Mayday Dental
                    account. If you wish to use the same credit card or ACH for
                    all locations, then please proceed with adding office
                    locations. Please contact Mayday Dental at 888-899-4386 with
                    any questions.
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{
                padding: '20px',
                gap: '20px',
              }}
            >
              <Grid item xs={12} sm={4} md={3}>
                <FormControl variant="outlined" fullWidth>
                  <Field
                    as={TextField}
                    id="place_name"
                    name="place_name"
                    label="Practice Name"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.place_name}
                  />
                  <ErrorMessage
                    name="place_name"
                    component="small"
                    style={{ color: 'red' }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <hr />

            <div
              className="d-flex flex-column"
              style={{
                gap: '20px',
                padding: '20px',
              }}
            >
              <h3
                style={{
                  color: '#262626',
                  fontSize: '16px',
                  fontWeight: '500',
                }}
              >
                Location Contact Info
              </h3>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      as={TextField}
                      id="first_name"
                      name="first_name"
                      label="First Name"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.first_name}
                    />
                    <ErrorMessage
                      name="first_name"
                      component="small"
                      style={{ color: 'red' }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      as={TextField}
                      id="location_email"
                      name="location_email"
                      label="Location E-mail"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.location_email}
                    />
                    <ErrorMessage
                      name="location_email"
                      component="small"
                      style={{ color: 'red' }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      as={TextField}
                      id="last_name"
                      name="last_name"
                      label="Last Name"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.last_name}
                    />
                    <ErrorMessage
                      name="last_name"
                      component="small"
                      style={{ color: 'red' }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <PhoneNumberInput
                      value={values.location_phone}
                      onChange={(value) => {
                        handleChange({
                          target: { name: 'location_phone', value },
                        });
                      }}
                      label="Location Phone"
                      isRequired
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <h3
                style={{
                  color: '#262626',
                  fontSize: '16px',
                  fontWeight: '500',
                }}
              >
                Address Details
              </h3>

              {/*<Grid container sx={{ padding: '0', margin: '0' }}>
                <Grid item xs={12} sm={3} md={2}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      id="zip"
                      name="zip"
                      label="Zip"
                      value={zip}
                      onChange={handleZipCodeChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="right">
                            {addressLoader ? (
                              <CircularProgress size="1rem" />
                            ) : (
                              <>
                                {addressValidZip ? (
                                  <CheckIcon />
                                ) : (
                                  <>
                                    {zip.length >= 5 ? (
                                      <ClearIcon onClick={(e) => {}} />
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      onInput={(e) => {
                        if (e.target.value.length > 5) {
                          e.target.value = e.target.value.slice(0, 5);
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      options={stateOptions}
                      getOptionLabel={(option) => option.label}
                      value={stateValue}
                      onChange={(event, newValue) => {
                        setStateValue(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="State" variant="outlined" />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      options={cityOptions}
                      getOptionLabel={(option) => option.label}
                      value={cityValue}
                      onChange={(event, newValue) => {
                        setCityValue(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="City" variant="outlined" />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>*/}
              <Grid container spacing={2}>
                {/* 
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      id="address"
                      name="address"
                      label="Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </FormControl>
                </Grid>  */}

                <Grid item sm={8} md={6}>
                  <Grid container spacing={2}>
                    <GeneralAddress
                      defaultValues={officeAddressData}
                      labels={{
                        zip: 'Zip Code',
                        state: 'State',
                        city: 'City',
                        address: 'Address',
                      }}
                      names={{
                        zip: 'zip',
                        state: 'state_id',
                        city: 'city_id',
                        address: 'address',
                      }}
                      updateAddressData={updateOfficeAddressData}
                      gridProps={{ xs: 12, sm: 6 }}
                    />
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id="timeZone">Time Zone</InputLabel>
                        <Select
                          id="timeZone"
                          name="time_zone_id"
                          label="Time Zone"
                          value={values.time_zone_id}
                          onChange={(e) => {
                            setTimeZoneId(e.target.value);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {timeZoneOptions.map((timeZone) => (
                            <MenuItem key={timeZone.id} value={timeZone.id}>
                              {timeZone.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <ErrorMessage
                          name="time_zone_id"
                          component="small"
                          style={{ color: 'red' }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <p
                style={{
                  color: '#595959',
                  fontSize: '14px',
                  marginTop: '20px',
                  width: '100%',
                }}
              >
                <b>Reminder:</b> Click on "Payment Methods" under "My Account"
                to add a valid payment method in order to create a job posting.
              </p>

              <Grid
                container
                spacing={2} // Adds spacing between the buttons
                sx={{
                  marginTop: '20px',
                }}
              >
                <Grid item xs={12} sm="auto">
                  <button
                    className="btn btn-primary"
                    style={{
                      backgroundColor: '#fff',
                      color: '#2561B0',
                      padding: '10px 20px',
                      borderRadius: '5px',
                      border: '1px solid #2561B0',
                      width: '100%', // Full width on small screens
                    }}
                    onClick={() => navigate(`/owner/account/locations`)}
                  >
                    Close
                  </button>
                </Grid>

                <Grid item xs={12} sm="auto">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: '#2561B0',
                      color: '#fff',
                      padding: '10px 30px',
                      borderRadius: '5px',
                      border: 'none',
                      width: '100%',
                    }}
                  >
                    OK
                  </button>
                </Grid>
              </Grid>
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default AddOwnerLocation;
