import {
  CloseOutlined,
  ErrorOutlineOutlined,
  MyLocation,
  Person2Outlined,
} from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../../components/Layout';
import { API_BASE_URL } from '../../../config/constants';
import GeneralAddress from '../../GeneralAddress';
import PhoneNumberInput from '../../../components/General/PhoneNumberInput';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

const EditOwnerLocation = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  // const [latitude, setLatitude] = useState(null);
  // const [longitude, setLongitude] = useState(null);
  // const [stateValue, setStateValue] = useState(null);
  // const [stateOptions, setStateOptions] = useState([]);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  // const [cityValue, setCityValue] = useState(null);
  // const [cityOptions, setCityOptions] = useState([]);

  const [location, setLocation] = useState(null);

  const [locationName, setLocationName] = useState(
    location ? location.place_name : ''
  );
  const [firstName, setFirstName] = useState(
    location ? location.contact_first_name : ''
  );
  const [lastName, setLastName] = useState('');
  const [locationEmail, setLocationEmail] = useState('');
  const [locationPhone, setLocationPhone] = useState('');
  // const [address, setAddress] = useState('');
  // const [zip, setZip] = useState('');
  const [timeZoneId, setTimeZoneId] = useState('');
  // const [cityId, setCityId] = useState('');
  // const [stateId, setStateId] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openState, setOpenState] = useState(false);
  const [openCity, setOpenCity] = useState(false);
  // const [loader, setLoader] = useState(false);
  const [validZip, setValidZip] = useState(false);
  const divRef = useRef();
  const [initialValues, setInitialValues] = useState({
    place_name: '',
    first_name: '',
    last_name: '',
    location_email: '',
    location_phone: '',
    time_zone_id: '',

  });

  function handleClick() {
    setAnchorEl(divRef.current);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id_pop = open ? 'simple-popover' : undefined;

  // const handleCityInputChange = (event, value) => {
  //   getCities(value, 1);
  // };

  const fetchLocation = () => {
    axios
      .get(`${API_BASE_URL}/owner/locations/${id}`, {
        headers: {
          method: 'GET',
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      })
      .then((res) => {
        const locationData = res.data.data;
        setLocation(locationData);

        setLocationName(locationData ? locationData.place_name : '');
        setFirstName(locationData ? locationData.contact_first_name : '');
        setLastName(locationData ? locationData.contact_last_name : '');
        setLocationEmail(locationData ? locationData.location_email : '');
        setLocationPhone(locationData ? locationData.location_phone : '');
        setTimeZoneId(locationData ? locationData.time_zone_id : '');

        updateOfficeAddressData('zip', locationData ? locationData.zip : '');
        updateOfficeAddressData(
          'state_id',
          locationData ? locationData.state_id : ''
        );
        updateOfficeAddressData(
          'city_id',
          locationData ? locationData.city_id : ''
        );
        updateOfficeAddressData(
          'address',
          locationData ? locationData.address : ''
        );

        setInitialValues({
          place_name: locationData ? locationData.place_name : '',
          first_name: locationData ? locationData.contact_first_name : '',
          last_name: locationData ? locationData.contact_last_name : '',
          location_email: locationData ? locationData.location_email : '',
          location_phone: locationData ? locationData.location_phone : '',
          time_zone_id: locationData ? locationData.time_zone_id : '',
        });
      })
      .catch((e) => console.log(e));
  };

  // const getStates = async () => {
  //   try {
  //     const stateRes = await axios.get(`${API_BASE_URL}/states`);

  //     const statesArray = Object.values(stateRes.data.data).map((state) => ({
  //       value: state.id,
  //       label: state.name,
  //     }));
  //     // setStateOptions(statesArray);
  //     // if (location.state_id) {
  //     //   const stateValue = statesArray.find(
  //     //     (option) => option.value === parseInt(location.state_id)
  //     //   );
  //     //   setStateValue(stateValue);
  //     // }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const [officeAddressData, setOfficeAddressData] = useState({
    zip: '',
    state_id: null,
    city_id: null,
    address: '',
    latitude: '',
    longitude: '',
  });

  const updateOfficeAddressData = (field, value) => {
    console.log(field, value);
    setOfficeAddressData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async (values) => {
    // e.preventDefault();

    const data = {
      place_name: values.place_name,
      contact_first_name: values.first_name,
      contact_last_name: values.last_name,
      location_email: values.location_email,
      location_phone: values.location_phone,
      address: officeAddressData.address,
      zip: officeAddressData.zip,
      time_zone_id: timeZoneId,
      city_id: officeAddressData.city_id,
      state_id: officeAddressData.state_id,
    };

    axios
      .put(`${API_BASE_URL}/owner/locations/${id}`, data, {
        headers: {
          method: 'PUT',
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      })
      .then((res) => {
        navigate(`/owner/account/locations`);
      })
      .catch((e) => console.log(e));
  };

  const getTimeZones = async () => {
    try {
      const timeZoneRes = await axios.get(`${API_BASE_URL}/zones`);

      const timeZoneArray = Object.values(timeZoneRes.data.data).map(
        (timeZone) => ({
          id: timeZone.id,
          label: timeZone.zone_name,
        })
      );
      setTimeZoneOptions(timeZoneArray);
    } catch (error) {}
  };

  // const getCities = async (cityQuery, cityPage) => {
  //   try {
  //     const cityRes = await axios.get(
  //       `${API_BASE_URL}/cities?name=${cityQuery}&page=${cityPage}`
  //     );

  //     const citiesArray = Object.values(cityRes.data.data).map((city) => ({
  //       value: city.id,
  //       label: city.name,
  //     }));

  //     if (cityPage === 1) {
  //       setCityOptions(citiesArray);
  //     } else {
  //       setCityOptions((prevOptions) => [...prevOptions, ...citiesArray]);
  //     }

  //     if (location.city_id) {
  //       const cityValue = citiesArray.find(
  //         (option) => option.value === parseInt(location.city_id)
  //       );
  //       setCityValue(cityValue);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const getCityStateByZip = async (zip) => {
  //   try {
  //     setLoader(true);
  //     const response = await axios.get(`${API_BASE_URL}/get-city-state/${zip}`);

  //     const { state_city, status, lat_lon, formatted_address } =
  //       response.data.data.data;

  //     if (status === 'OK') {
  //       const statesArray = [
  //         {
  //           value: state_city.id,
  //           label: state_city.name,
  //         },
  //       ];

  //       const citiesArray = Object.values(state_city.cities).map((city) => ({
  //         value: city.id,
  //         label: city.name,
  //       }));

  //       setStateOptions(statesArray);
  //       setCityOptions(citiesArray);
  //       setStateValue(statesArray[0]);
  //       setCityValue(citiesArray[0]);
  //       setStateId(statesArray[0].value);
  //       setCityId(citiesArray[0].value);
  //       setLongitude(lat_lon.longitude);
  //       setLatitude(lat_lon.latitude);
  //       setAddress(formatted_address);
  //       setValidZip(true);
  //       setLoader(false);
  //       // setOpenState(true);
  //       // setOpenCity(true);
  //     } else {
  //       setStateOptions([]);
  //       setCityOptions([]);
  //       setLoader(false);
  //       setValidZip(false);
  //       setStateId(null);
  //       setCityId(null);
  //       handleClick();
  //       console.error('No results found for the given ZIP code');
  //     }
  //   } catch (error) {
  //     // Handle error from the API request
  //     console.error('Error fetching data from Google Maps API:', error);
  //   }
  // };

  // const handleZipCodeChange = async (e) => {
  //   const newZip = e.target.value;
  //   setZip(e.target.value);
  //   setValidZip(false);
  //   if (/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(e.target.value)) {
  //     await getCityStateByZip(newZip);
  //   }
  // };

  useEffect(() => {
    fetchLocation();
    getTimeZones();
  }, []);

  useEffect(() => {
    // getStates();
    // getCities('', 1);
  }, []);

  // useEffect(() => {
  // setLocationName(location ? location.place_name : '');
  // setFirstName(location ? location.contact_first_name : '');
  // setLastName(location ? location.contact_last_name : '');
  // setLocationEmail(location ? location.location_email : '');
  // setLocationPhone(location ? location.location_phone : '');
  // setTimeZoneId(location ? location.time_zone_id : '');

  // updateOfficeAddressData('zip', location ? location.zip : '');
  // updateOfficeAddressData('state_id', location ? location.state_id : '');
  // updateOfficeAddressData('city_id', location ? location.city_id : '');
  // updateOfficeAddressData('address', location ? location.address : '');

  // if (location) {
  //   const citiesArray = [
  //     {
  //       value: location.city.id,
  //       label: location.city.name,
  //     },
  //   ];
  //   const statesArray = [
  //     {
  //       value: location.state.id,
  //       label: location.state.name,
  //     },
  //   ];
  // setCityOptions(citiesArray);
  // setCityValue(citiesArray[0]);
  // setStateOptions(statesArray);
  // setStateValue(statesArray[0]);
  // }
  // }, [location]);

  const validationSchema = Yup.object().shape({
    place_name: Yup.string().required('Practice Name is required'),
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    location_email: Yup.string()
      .email('Invalid email')
      .required('Email is required'),
    location_phone: Yup.string().required('Phone number is required'),
    time_zone_id: Yup.string().required('Time Zone is required'),
    // Add validation for address fields as needed
  });

  return (
    <Layout
      items={[
        {
          name: 'My Account',
          link: '/',
          icon: <Person2Outlined sx={{ py: 0.2 }} />,
        },
        {
          name: 'Locations',
          link: '/owner/account/profile',
          icon: <MyLocation sx={{ py: 0.2 }} />,
        },
      ]}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values) => {
          // Handle form submission
          console.log('submit the form', values);
          handleSubmit(values);
        }}
        // onSubmit={handleSubmit}
      >
        {({ handleChange, handleBlur, values }) => (
          <Form>
            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                borderBottom: '1px solid #D9D9D9',
                padding: '20px',
              }}
            >
              <Grid>
                <h4 className="pb-0 mb-1" style={{ color: '#262626' }}>
                  Edit Location
                </h4>
                <p style={{ color: '#8C8C8C', fontSize: '0.8rem' }}>
                  Management of Locations
                </p>
              </Grid>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => navigate(`/owner/account/locations`)}
              >
                <p style={{ marginRight: '10px', color: '#808080' }}>Close</p>
                <CloseOutlined
                  sx={{
                    color: '#000',
                    fontSize: '42px',
                    borderRadius: '50%',
                    border: '1px solid #ccc',
                    padding: '8px',
                    backgroundColor: '#fff',
                  }}
                />
              </div>
            </div>

            <Grid
              container
              sx={{
                padding: '20px',
              }}
            >
              <Grid
                item
                lg={8}
                xl={5}
                sx={{
                  backgroundColor: '#D7E8FF',
                  padding: 2,
                  borderRadius: '8px',
                  display: 'flex',
                  gap: 2,
                }}
              >
                <Grid item>
                  <ErrorOutlineOutlined
                    sx={{ color: '#4A93F0', transform: 'rotate(180deg)' }}
                  />
                </Grid>
                <Grid item sx={{ fontSize: '12px' }}>
                  <p
                    style={{
                      color: '#194378',
                      fontWeight: '600',
                      marginBottom: 0,
                    }}
                  >
                    Note
                  </p>
                  <p style={{ color: '#194378' }}>
                    If you want to set up a specific payment type (ie: bank
                    account or Credit Card) for payments on the new added
                    location, you will need to create a new Mayday Dental
                    account. If you wish to use the same credit card or ACH for
                    all locations, then please proceed with adding office
                    locations. Please contact Mayday Dental at 888-899-4386 with
                    any questions.
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{
                padding: '20px',
                gap: '20px',
              }}
            >
              <Grid item xs={12} sm={4} md={3}>
                <FormControl variant="outlined" fullWidth>
                  <Field
                    as={TextField}
                    id="place_name"
                    name="place_name"
                    label="Practice Name"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.place_name}
                  />
                  <ErrorMessage
                    name="place_name"
                    component="small"
                    style={{ color: 'red' }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <hr />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                padding: '20px',
              }}
            >
              <h3
                style={{
                  color: '#262626',
                  fontSize: '16px',
                  fontWeight: '500',
                }}
              >
                Location Contact Info
              </h3>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      as={TextField}
                      id="first_name"
                      name="first_name"
                      label="First Name"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.first_name}
                    />
                    <ErrorMessage
                      name="first_name"
                      component="small"
                      style={{ color: 'red' }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      as={TextField}
                      id="location_email"
                      name="location_email"
                      label="Location E-mail"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.location_email}
                    />
                    <ErrorMessage
                      name="location_email"
                      component="small"
                      style={{ color: 'red' }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      as={TextField}
                      id="last_name"
                      name="last_name"
                      label="Last Name"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.last_name}
                    />
                    <ErrorMessage
                      name="last_name"
                      component="small"
                      style={{ color: 'red' }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <PhoneNumberInput
                      value={values.location_phone}
                      onChange={(value) => {
                        handleChange({ target: { name: 'location_phone', value } });
                      }}
                      label="Location Phone"
                      isRequired
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <h3
                style={{
                  color: '#262626',
                  fontSize: '16px',
                  fontWeight: '500',
                }}
              >
                Address Details
              </h3>

              {/*<Grid container sx={{ padding: '0', margin: '0' }}>
                <Grid item xs={12} sm={3} md={2}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      id="zip"
                      name="zip"
                      label="Zip"
                      value={zip}
                      onChange={handleZipCodeChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="right">
                            {addressLoader ? (
                              <CircularProgress size="1rem" />
                            ) : (
                              <>
                                {addressValidZip ? (
                                  <CheckIcon />
                                ) : (
                                  <>
                                    {zip.length >= 5 ? (
                                      <ClearIcon onClick={(e) => {}} />
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      onInput={(e) => {
                        if (e.target.value.length > 5) {
                          e.target.value = e.target.value.slice(0, 5);
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      options={stateOptions}
                      getOptionLabel={(option) => option.label}
                      value={stateValue}
                      onChange={(event, newValue) => {
                        setStateValue(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="State" variant="outlined" />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      options={cityOptions}
                      getOptionLabel={(option) => option.label}
                      value={cityValue}
                      onChange={(event, newValue) => {
                        setCityValue(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="City" variant="outlined" />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>*/}
              <Grid container spacing={2}>
                {/* 
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      id="address"
                      name="address"
                      label="Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </FormControl>
                </Grid>  */}

                <Grid item sm={8} md={6}>
                  <Grid container spacing={2}>
                    <GeneralAddress
                      defaultValues={officeAddressData}
                      labels={{
                        zip: 'Zip Code',
                        state: 'State',
                        city: 'City',
                        address: 'Address',
                      }}
                      names={{
                        zip: 'zip',
                        state: 'state_id',
                        city: 'city_id',
                        address: 'address',
                      }}
                      updateAddressData={updateOfficeAddressData}
                      gridProps={{ xs: 12, sm: 6 }}
                    />
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id="timeZone">Time Zone</InputLabel>
                        <Select
                          id="timeZone"
                          name="time_zone_id"
                          label="Time Zone"
                          value={values.time_zone_id}
                          onChange={(e) => {
                            setTimeZoneId(e.target.value);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {timeZoneOptions.map((timeZone) => (
                            <MenuItem key={timeZone.id} value={timeZone.id}>
                              {timeZone.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <ErrorMessage
                          name="time_zone_id"
                          component="small"
                          style={{ color: 'red' }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <p
                style={{
                  color: '#595959',
                  fontSize: '14px',
                  marginTop: '20px',
                  width: '100%',
                }}
              >
                <b>Reminder:</b> Click on "Payment Methods" under "My Account"
                to add a valid payment method in order to create a job posting.
              </p>

              <Grid
                container
                spacing={2} // Adds spacing between the buttons
                sx={{
                  marginTop: '20px',
                }}
              >
                <Grid item xs={12} sm="auto">
                  <button
                    className="btn btn-primary"
                    style={{
                      backgroundColor: '#fff',
                      color: '#2561B0',
                      padding: '10px 20px',
                      borderRadius: '5px',
                      border: '1px solid #2561B0',
                      width: '100%', // Full width on small screens
                    }}
                    onClick={() => navigate(`/owner/account/locations`)}
                  >
                    Close
                  </button>
                </Grid>

                <Grid item xs={12} sm="auto">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: '#2561B0',
                      color: '#fff',
                      padding: '10px 30px',
                      borderRadius: '5px',
                      border: 'none',
                      width: '100%',
                    }}
                  >
                    OK
                  </button>
                </Grid>
              </Grid>
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default EditOwnerLocation;
