import {
  FormControl,
  TextField,
  InputAdornment,
  CircularProgress,
  Grid,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import useAddressLookup from '../../../hooks/useAddressLookup';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import GeneralAddress from '../../../pages/GeneralAddress';

const BillingDetailsFields = ({ billingAddressData, setBillingAddressData }) => {
  // const [zip, setZip] = useState('');
  // const [city, setCity] = useState('');
  // const [state, setState] = useState('');
  // const [address, setAddress] = useState('');

  const updateBillingAddress = (field, value) => {
    setBillingAddressData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // const {
  //   cityOptions,
  //   stateOptions,
  //   validZip,
  //   address: fetchedAddress,
  //   loader: addressLoader,
  // } = useAddressLookup(zip);

  // useEffect(() => {
  //   if (validZip) {
  //     if (stateOptions.length > 0) {
  //       setState(stateOptions[0].label);
  //     }
  //     if (cityOptions.length > 0) {
  //       setCity(cityOptions[0].label);
  //     }
  //     setAddress(fetchedAddress || '');
  //   } else {
  //     setCity('');
  //     setState('');
  //     setAddress('');
  //   }
  // }, [validZip, stateOptions, cityOptions, fetchedAddress]);

  return (
    <Grid container spacing={2} className="mb-2">
      <Grid item xs={12} md={6}>
        <FormControl variant="outlined" className="w-100">
          <TextField
            required
            className="w-100"
            name="name"
            label="Name"
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl variant="outlined" className="w-100 mb-2">
          <TextField
            required
            className="w-100"
            type="email"
            name="email"
            label="Email"
            variant="outlined"
          />
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <FormControl  variant="outlined" className="w-100 mb-2">
          <TextField
            required
            className="w-100"
            label="Zip"
            name="zip"
            
            variant="outlined"
            sx={{ mb: 2 }}
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            onInput={(e) => {
              if (e.target.value.length > 5) {
                e.target.value = e.target.value.slice(0, 5);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {addressLoader ? (
                    <CircularProgress size="1rem" />
                  ) : validZip ? (
                    <CheckIcon />
                  ) : zip.length >= 5 ? (
                    <ClearIcon onClick={() => setZip('')} />
                  ) : null}
                </InputAdornment>
              ),
              pattern: '^\\d{5}$',
              title: 'ZIP code can be only 5-digit',
              minLength: 5,
              maxLength: 5,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl  variant="outlined" className="w-100 mb-2">
          <TextField
            required
            className="w-100"
            label="State"
            name="state"
            
            variant="outlined"
            sx={{ mb: 2 }}
            value={state}
            readOnly
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={5.5}>
        <FormControl  variant="outlined" className="w-100 mb-2">
          <TextField
            required
            className="w-100"
            name="city"
            label="City"
            
            variant="outlined"
            sx={{ mb: 2 }}
            value={city}
            readOnly
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={5.5}>
        <FormControl variant="outlined" className="w-100 mb-2">
          <TextField
            required
            className="w-100"
            name="address"
            label="Address"
            
            variant="outlined"
            sx={{ mb: 2 }}
            value={address}
            readOnly
          />
        </FormControl>
      </Grid> */}
      <GeneralAddress
        defaultValues={billingAddressData}
        labels={{
          zip: 'Zip Code',
          state: 'State',
          city: 'City',
          address: 'Address',
        }}
        names={{
          zip: 'zip',
          state: 'state_id',
          city: 'city_id',
          address: 'address',
        }}
        gridProps={{ xs: 12, sm: 6 }}
        updateAddressData={updateBillingAddress}
      />
    </Grid>
  );
};

export default BillingDetailsFields;
